import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c(VTooltip, {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "color": "warning",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.abortTask
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("fas fa-ban")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Aufgabe abbrechen")])]), _c(VTooltip, {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "color": "red",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.unregisterTablet
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("fas fa-trash")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Tablet löschen")])])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };