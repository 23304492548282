var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _vm.text ? _c('HelpTooltip', {
    attrs: {
      "text": _vm.text
    }
  }) : _vm._e();
};

var staticRenderFns = [];
export { render, staticRenderFns };