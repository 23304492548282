import { r4cApi, metricsApi } from '@/services/data';
import { ITabletExtended } from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import { authStore } from './authStore';

Vue.use(Vuex);

 
const { store, rootActionContext } = createDirectStore({
  state: () => ({
    tablets: [] as ITabletExtended[],
    tabletRegisterUrl: '',
    apiKey: '',
    isLocked: false,
    notification: { active: false, type: 'info', message: '' },
  }),
  getters: {
    tablets(state) {
      return state.tablets;
    },
    tabletRegisterUrl(state) {
      return state.tabletRegisterUrl;
    },
    apiKey(state) {
      return state.apiKey;
    },
    notification(state) {
      return state.notification;
    },
  },
  mutations: {
    setTablets(state, data: ITabletExtended[]) {
      state.tablets = data;
    },
    setTabletRegisterUrl(state, data: string) {
      state.tabletRegisterUrl = data;
    },
    setApiKey(state, data: string) {
      state.apiKey = data;
    },
    setIsLocked(state, data: boolean) {
      state.isLocked = data;
      if (state.isLocked) {
        state.notification = {
          active: true,
          type: 'warning',
          message:
            'Bitte beachten Sie, dass die Hintergrundaktualisierung während Sie Änderungen vornehmen deaktiviert ist.',
        };
      } else {
        state.notification = { active: false, type: 'info', message: '' };
      }
    },
    setNotification(state, data: { active: boolean; type: string; message: string }) {
      state.notification = data;
    },
  },
  actions: {
    async init(context) {
      const { dispatch } = rootActionContext(context);

      await Promise.all([dispatch.initTablets(), dispatch.initApiKey()]);
      await dispatch.initTabletRegisterUrl();
    },
    async initTablets(context) {
      const { state, commit } = rootActionContext(context);
      const r4chost = authStore.getters.profile?.r4chost;

      if (!state.isLocked && r4chost) {
        const tablets = await r4cApi.tablet.getTablets(r4chost);
        commit.setTablets(tablets);
      }
    },
    async initApiKey(context) {
      const { commit } = rootActionContext(context);

      const apiKey = await metricsApi.auth.loadApiKey();
      if (apiKey.success && apiKey.data) {
        commit.setApiKey(apiKey.data.apikey);
      }
    },
    async initTabletRegisterUrl(context) {
      const { state, commit } = rootActionContext(context);
      const r4chost = authStore.getters.profile?.r4chost;

      if (!r4chost) {
        return;
      }

      const res = await r4cApi.tablet.getTabletRegisterUrl(r4chost, state.apiKey);
      if (res.ok) {
        commit.setTabletRegisterUrl(res.registerUrl);
      }
    },
    async cancelTabletTask(context, token: ITabletExtended['token']) {
      const { dispatch } = rootActionContext(context);

      console.log('cancel task', token);
      const r4chost = authStore.getters.profile?.r4chost;

      if (!r4chost) {
        return;
      }
      const res = await r4cApi.tablet.cancelTabletTask(r4chost, token);
      console.log('cancel task response', res);

      await dispatch.initTablets();
    },
    async unregisterTablet(context, token: ITabletExtended['token']) {
      const { dispatch } = rootActionContext(context);

      const r4chost = authStore.getters.profile?.r4chost;
      if (!r4chost) {
        return;
      }
      console.log('unregister tablet', token);
      const res = await r4cApi.tablet.unregisterTablet(r4chost, token);
      console.log('unregister tablet response', res);

      await dispatch.initTablets();
    },
    async renameTablet(context, { token, name }: { token: ITabletExtended['token']; name: string }) {
      const { dispatch } = rootActionContext(context);

      const r4chost = authStore.getters.profile?.r4chost;
      if (!r4chost) {
        return;
      }
      console.log('rename tablet', token, name);
      const res = await r4cApi.tablet.renameTablet(r4chost, token, name);
      console.log('rename tablet response', res);
      tabletStore.commit.setIsLocked(false);
      await dispatch.initTablets();
    },
  },
});

export const tabletStore = store;
