import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "external-access-cell d-flex"
  }, [_vm.params.value ? _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c(VIcon, _vm._g(_vm._b({
          attrs: {
            "color": "success",
            "dense": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v("fas fa-check")])];
      }
    }], null, false, 1066295344)
  }, [_c('span', [_vm._v(_vm._s(_vm.params.data.mainclientid) + " - " + _vm._s(_vm.params.data.mainclientname))])]) : _c(VIcon, {
    attrs: {
      "color": "error",
      "dense": ""
    }
  }, [_vm._v("fas fa-times")])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };