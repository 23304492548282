import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex flex-grow-1 align-center"
  }, [_c(VTextField, {
    attrs: {
      "dense": "",
      "outlined": "",
      "flat": "",
      "hide-details": "",
      "placeholder": "Patienten-ID",
      "rules": [function (v) {
        return !isNaN(v) || "Patienten-ID darf nicht leer sein";
      }]
    },
    model: {
      value: _vm.patid,
      callback: function callback($$v) {
        _vm.patid = $$v;
      },
      expression: "patid"
    }
  }), _c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "icon": "",
      "color": "primary",
      "disabled": !_vm.isSaveable,
      "loading": _vm.isSaving
    },
    on: {
      "click": _vm.save
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-save")])], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };