/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./StichwortCell.vue?vue&type=template&id=d58d81e8&scoped=true&lang=pug"
import script from "./StichwortCell.vue?vue&type=script&lang=ts"
export * from "./StichwortCell.vue?vue&type=script&lang=ts"
import style0 from "./StichwortCell.vue?vue&type=style&index=0&id=d58d81e8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d58d81e8",
  null
  
)

export default component.exports