var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('Chart', {
    attrs: {
      "options": _vm.chartOptions
    }
  });
};

var staticRenderFns = [];
export { render, staticRenderFns };