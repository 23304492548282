var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _vm.behandler ? _c('div', {
    staticClass: "d-flex py-2"
  }, [_c('BehandlerChip', {
    attrs: {
      "behandler": _vm.behandler,
      "in-table": ""
    }
  })], 1) : _c('div', {
    staticClass: "no-behandler"
  }, [_vm._v("YYY")]);
};

var staticRenderFns = [];
export { render, staticRenderFns };