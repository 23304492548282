import { createSharedComposable, useEventSource, UseEventSourceReturn } from '@vueuse/core';
import { computed } from 'vue';
import { EventStreamConfig, StringArrayToObject, collectorServerEvents } from '@rose/types';
import {
  AuthRequestStateGetter,
  createCollectorApi,
  createMetricsApi,
  createR4cApi,
  createRoseApiWithAxios,
  getCollectorUrl,
} from '@rose/common-ui';
import { createFactoringApi } from '@rose/common-ui/src/api/r4c/factoringApi';
import { provideAuthStateGetter, provideRoseApi } from '@rose/common-ui/src/services/commonApi';
import { authStore } from '@/state/authStore';
import { filter } from 'lodash';
import { createDoctosyncApi } from '@rose/common-ui/src/api/doctosyncApi';

const authRequestStateGetter: AuthRequestStateGetter = () => ({
  authToken: authStore.state.authToken,
  cid: authStore.state.cid,
  r4chost: authStore.state.user?.r4chost,
});

const { roseApi } = createRoseApiWithAxios(authRequestStateGetter);

export const metricsApi = createMetricsApi(roseApi);
export const collectorApi = createCollectorApi(roseApi);
export const factoringApi = createFactoringApi(roseApi);
export const r4cApi = createR4cApi(roseApi);
export const doctosyncApi = createDoctosyncApi(roseApi);

provideRoseApi(roseApi);
provideAuthStateGetter(authRequestStateGetter);

// SSE
export const parseEventSourceData = <T>(eventSource: UseEventSourceReturn) => ({
  ...eventSource,
  data: computed(() => {
    const data = eventSource.data.value;
    if (data) {
      return JSON.parse(data) as T;
    } else {
      return null;
    }
  }),
});

function useRoseEventSource<DataType, Params extends readonly string[]>(
  eventStreamConfig: EventStreamConfig<DataType, Params>,
  paramsGetter: () => StringArrayToObject<Params>,
) {
  return createSharedComposable(() => {
    const params = paramsGetter();

    const eventParams = eventStreamConfig.params;
    // check if all params are present
    const missingParams = filter(eventParams, (p: keyof StringArrayToObject<Params>) => !params[p]);
    if (missingParams.length > 0) {
      throw new Error(`missing params for event ${eventStreamConfig.path}: ${missingParams.join(', ')}`);
    }

    const collectorUrl = getCollectorUrl();

    // convert params object to query string
    const queryString = Object.entries({ ...params, jwt: authStore.state.authToken, cid: authStore.state.cid })
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return parseEventSourceData<DataType>(
      useEventSource(`${collectorUrl}/events${eventStreamConfig.path}?${queryString}`),
    );
  });
}

export const useZimmerbelegungEventSource = useRoseEventSource(collectorServerEvents.zimmerBelegung, () => ({}));
