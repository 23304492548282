import {
  IFactoringClientRechenzentren,
  IRoseClientInfoForOnboarding,
  IFactoringClientRechenzentrumDB,
  IFactoringSetting,
  IFactoringVertragOderMandant,
  IFactoringRechenzentrumInfo,
} from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import { authStore } from '@/state/authStore';
import { metricsApi, factoringApi } from '@/services/data';
import * as lzs from 'lz-string';
import { onboardingUrl } from '@rose/common-ui';
import Vue from 'vue';
import { isEqual, cloneDeep, first, filter, includes, isEmpty, map, toNumber } from 'lodash';
import { clientStateStore } from '@/state/clientStateStore';
import { factoringStore } from '@/state/factoringStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
import { rechenzentren } from '../../../../base';
import * as jsondiffpatch from 'jsondiffpatch';
import * as jsonpatch from 'fast-json-patch';

export interface EditFactoringState {
  editClientRZs: IFactoringClientRechenzentren<IFactoringClientRechenzentrumDB>;
  editSelectedClientRZ?: IFactoringClientRechenzentrumDB;
  editRzUrl: string;
}

const editFactoringState: EditFactoringState = {
  editClientRZs: {} as IFactoringClientRechenzentren<IFactoringClientRechenzentrumDB>,
  editSelectedClientRZ: {} as IFactoringClientRechenzentrumDB,
  editRzUrl: '',
};

const { store, rootActionContext } = createDirectStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => editFactoringState,
  getters: {
    isModified(state): boolean {
      if (!isEqual(factoringStore.state.clientRZs, state.editClientRZs)) {
        console.log('factoring modified', state.editClientRZs);
        return true;
      }
      return false;
    },
    getCurrentFactoringDiff(state): jsondiffpatch.Delta | undefined {
      return jsondiffpatch
        .create({
          objectHash: function (obj: any, index: number) {
            // try to find an id property, otherwise just use the index in the array
            return obj.id || obj.name || '$$index:' + index;
          },
        })
        .diff(factoringStore.state.clientRZs, state.editClientRZs);
    },
    getCurrentEditingChanges(state) {
      // diff with checks
      return jsonpatch.compare(factoringStore.state.clientRZs, state.editClientRZs, true);
    },
    selectedClientRZSettings(state): IFactoringSetting | undefined {
      if (!state.editSelectedClientRZ?.rzkey) {
        console.warn('no rzkey in selectedRZ', state.editSelectedClientRZ?.rzkey);
        return;
      }
      return editSettingsStore.state.editSettings.factoring[state.editSelectedClientRZ.rzkey];
    },
    selectedClientRZMandantenAktiv(state, getter): IFactoringVertragOderMandant[] {
      const selectedClientRZSettings: IFactoringSetting | undefined = getter.selectedClientRZSettings;

      if (
        !selectedClientRZSettings?.aktiveMandantenReihenfolge ||
        !state.editSelectedClientRZ?.vertragsnummernodermandanten
      ) {
        return [];
      }

      const aktiveMandantenReihenfolge = selectedClientRZSettings.aktiveMandantenReihenfolge;

      // Filter and sort based on the aktiveMandantenReihenfolge array
      return state.editSelectedClientRZ.vertragsnummernodermandanten
        .filter(m => aktiveMandantenReihenfolge.includes(m.name))
        .sort((a, b) => aktiveMandantenReihenfolge.indexOf(a.name) - aktiveMandantenReihenfolge.indexOf(b.name));
    },
    selectedClientRZMandantenInaktiv(state, getter): IFactoringVertragOderMandant[] {
      const selectedClientRZSettings: IFactoringSetting | undefined = getter.selectedClientRZSettings;
      return filter(
        state.editSelectedClientRZ?.vertragsnummernodermandanten,
        m => !includes(selectedClientRZSettings?.aktiveMandantenReihenfolge, m.name),
      );
    },
    clientRechenZentrenInfo(state) {
      const rz = map(state.editClientRZs.rechenzentren, crz => ({
        key: crz.rzkey,
        name: rechenzentren[crz.rzkey].name,
        aktiv: includes(editSettingsStore.getters.rechzentrumReihenfolge, crz.rzkey),
      })) as IFactoringRechenzentrumInfo[];
      return rz ? rz : null;
    },
  },
  mutations: {
    setEditClientRZs(state, data: IFactoringClientRechenzentren<IFactoringClientRechenzentrumDB>) {
      state.editClientRZs = data;
    },
    setEditSelectedClientRZ(state, data: IFactoringClientRechenzentrumDB) {
      state.editSelectedClientRZ = data;
    },
    changeSelectedClientRZ(state, data: string) {
      state.editSelectedClientRZ = state.editClientRZs.rechenzentren.find(rz => rz.id === toNumber(data));
    },
    setEditRzUrl(state, editRzUrl: string) {
      state.editRzUrl = editRzUrl;
    },
    setSelectedRZSettings(state, data: Partial<IFactoringSetting>) {
      if (!state.editSelectedClientRZ?.rzkey) {
        console.warn('no rzkey in selectedRZ', state.editSelectedClientRZ?.rzkey);
        return;
      }
      editSettingsStore.commit.setSelectedRZSettings({ rz: state.editSelectedClientRZ.rzkey, settings: data });
    },
    setSelectedRZCredentials(state, data: IFactoringClientRechenzentrumDB['credentials']) {
      if (!state.editSelectedClientRZ) {
        console.warn('no RZ selected', state.editSelectedClientRZ);
        return;
      }
      if (!state.editClientRZs.decrypted) {
        console.warn('clientRZs not decrypted, skip', state.editClientRZs);
        return;
      }
      for (const dataKey in data) {
        if (Object.prototype.hasOwnProperty.call(data, dataKey)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          Vue.set(state.editSelectedClientRZ.credentials, dataKey, data[dataKey]);
        }
      }
    },
    setSelectedRechenzentrumAuslandErlauben(state, data: IFactoringClientRechenzentrumDB['auslanderlauben']) {
      if (!state.editSelectedClientRZ) {
        console.warn('no RZ selected', state.editSelectedClientRZ);
        return;
      }
      state.editSelectedClientRZ.auslanderlauben = data;
    },
  },
  actions: {
    async init(context) {
      const { dispatch } = rootActionContext(context);
      const hasR4c = authStore.getters.hasR4c || clientStateStore.getters.isFakeData;

      if (!hasR4c) {
        console.log('no r4c, skipping initialization of editFactoringState');
        return;
      }
      await Promise.all([dispatch.initClientRechenzentren(), dispatch.initEditRZUrl()]);
      await dispatch.initSelectedClientRZ();
    },
    async initEditRZUrl(context) {
      const { commit } = rootActionContext(context);
      const token = (await metricsApi.auth.createMidSessionToken()).token;
      const cid = authStore.state.cid;

      if (cid) {
        const onboardingInfo: IRoseClientInfoForOnboarding = {
          authToken: token,
          cid,
          r4cSecret: factoringStore.state.clientRZs.credentialsKey,
        };

        const compressed = lzs.compressToEncodedURIComponent(JSON.stringify(onboardingInfo));
        commit.setEditRzUrl(`${onboardingUrl}/edit-r4c-rz?info=${compressed}`);
      }
    },
    async initClientRechenzentren(context) {
      const { state, commit } = rootActionContext(context);
      await factoringStore.dispatch.init();
      const clientRZs = cloneDeep(factoringStore.state.clientRZs);

      if (isEmpty(state.editClientRZs)) {
        console.log('initializing editFactoringState', clientRZs);
      } else {
        console.log('reset editFactoringState', clientRZs);
      }

      commit.setEditClientRZs(cloneDeep(clientRZs));
    },
    initSelectedClientRZ(context) {
      const { state, commit } = rootActionContext(context);
      const firstRZ = first(state.editClientRZs.rechenzentren);

      if (!firstRZ) {
        console.warn('no rechenzentrum found');
        return;
      }

      commit.setEditSelectedClientRZ(firstRZ);
    },
    async save(context) {
      const { state, getters, dispatch } = rootActionContext(context);

      try {
        if (authStore.state.profile?.r4chost && getters.isModified) {
          console.log('editFactoringStore::updateClientRechenzentren', state.editClientRZs);
          await factoringApi.updateClientRechenzentren(authStore.state.profile?.r4chost, {
            rechenzentren: state.editClientRZs.rechenzentren,
            secret: state.editClientRZs.credentialsKey,
          });
          await dispatch.init();
        }
      } catch (e) {
        console.error('error saving factoringData', e);
        throw e;
      }
    },
  },
});

export const editFactoringStore = store;
