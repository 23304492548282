import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "dropdown"
  }, [_c(VSelect, {
    staticStyle: {
      "display": "inline-block"
    },
    attrs: {
      "items": _vm.options,
      "dense": "",
      "flat": "",
      "outlined": "",
      "hide-details": "",
      "small": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };