import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex grow text-center"
  }, [_c(VTextField, {
    attrs: {
      "placeholder": _vm.label,
      "dense": "",
      "outlined": "",
      "flat": "",
      "hide-details": "",
      "full-width": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clicked($event);
      }
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };