import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: null
    };
  },
  computed: {
    hasComments: function hasComments() {
      return this.params.data.hasComments;
    },
    hasHkpRef: function hasHkpRef() {
      return this.params.data.hasHkpRef;
    }
  },
  methods: {}
});