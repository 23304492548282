import { roseDayjs } from '../../../base';

export const fromNowFilter = {
  filters: {
    fromNow(date: Date | string, markDirty: string): string {
      if (date) {
        const m = roseDayjs(date);
        if (m.isValid()) {
          return m.fromNow();
        }
        return 'n/a';
      }
      return '-';
    },
  },
};
