/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./DownloadCell.vue?vue&type=template&id=8685bb96&scoped=true&lang=pug"
import script from "./DownloadCell.vue?vue&type=script&lang=ts"
export * from "./DownloadCell.vue?vue&type=script&lang=ts"
import style0 from "./DownloadCell.vue?vue&type=style&index=0&id=8685bb96&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8685bb96",
  null
  
)

export default component.exports