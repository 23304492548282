import { VAvatar } from 'vuetify/lib/components/VAvatar';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex flex-wrap justify-center align-center py-3"
  }, [_vm.termingruppe && _vm.termingruppe.farbe ? _c(VAvatar, {
    staticClass: "mr-1 pd-3",
    attrs: {
      "color": _vm.termingruppe.farbe,
      "left": ""
    }
  }) : _vm._e(), _vm.termingruppe ? _c('span', [_vm._v(_vm._s(_vm.termingruppe.bezeichnung))]) : _c('span', [_vm._v("-")])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };