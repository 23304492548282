import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    checked: {
      get: function get() {
        var _a;

        return !!((_a = this.params.value) === null || _a === void 0 ? void 0 : _a.value);
      },
      set: function set(value) {
        var _a;

        this.params.context.clicked({
          key: (_a = this.params.value) === null || _a === void 0 ? void 0 : _a.key,
          value: !!value
        });
      }
    },
    label: function label() {
      return this.params.context.label;
    }
  },
  methods: {
    clicked: function clicked(event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
});