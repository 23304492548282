import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "grid"
  }, [_c(VBtnToggle, {
    "class": _vm.getAutoConfiguredClass(),
    attrs: {
      "dense": "",
      "title": _vm.topLeistungen
    },
    model: {
      value: _vm.behandlerType,
      callback: function callback($$v) {
        _vm.behandlerType = $$v;
      },
      expression: "behandlerType"
    }
  }, [_c(VBtn, {
    attrs: {
      "value": "za"
    }
  }, [_vm._v("ZA")]), _c(VBtn, {
    attrs: {
      "value": "pzr"
    }
  }, [_vm._v("PZR")])], 1), !_vm.behandlerType ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c(VIcon, {
    staticClass: "justify-start",
    attrs: {
      "color": "warning",
      "left": ""
    }
  }, [_vm._v("fas fa-triangle-exclamation")]), _c('span', {
    staticClass: "caption warning--text"
  }, [_vm._v("Funktion wählen")])], 1) : _vm._e(), _vm.behandlerType === "pzr" ? _c(VSelect, {
    attrs: {
      "items": _vm.pzrOptions,
      "item-text": "name",
      "item-value": "value",
      "placeholder": "Position",
      "dense": "",
      "flat": "",
      "hide-details": "",
      "solo": ""
    },
    model: {
      value: _vm.selectedPosition,
      callback: function callback($$v) {
        _vm.selectedPosition = $$v;
      },
      expression: "selectedPosition"
    }
  }) : _vm.behandlerType === "za" ? _c(VSelect, {
    attrs: {
      "items": _vm.zaOptions,
      "item-text": "name",
      "item-value": "value",
      "placeholder": "Position",
      "dense": "",
      "flat": "",
      "hide-details": "",
      "solo": ""
    },
    model: {
      value: _vm.selectedPosition,
      callback: function callback($$v) {
        _vm.selectedPosition = $$v;
      },
      expression: "selectedPosition"
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };