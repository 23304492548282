import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { defineComponent } from 'vue';
import { ADMIN_LEVEL } from '@/../../types/';
export default defineComponent({
  components: {},
  data: function data() {
    return {
      params: {},
      icon: 'fas fa-user',
      text: 'Admin ohne Benutzerverwaltung'
    };
  },
  computed: {
    initials: function initials() {
      if (this.params.data) {
        return this.params.data.firstName.replace(/Dr\.\s/g, '').charAt(0) + this.params.data.lastName.charAt(0);
      }

      return '';
    },
    isTeam: function isTeam() {
      var _a;

      return ((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.adminLevel) === ADMIN_LEVEL.ROSE_TEAM;
    },
    isAdmin: function isAdmin() {
      var _a, _b;

      return ((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.adminLevel) === ADMIN_LEVEL.PRAXISMANAGER || ((_b = this.params.data) === null || _b === void 0 ? void 0 : _b.adminLevel) === ADMIN_LEVEL.CLIENT_ADMIN;
    },
    badgeColor: function badgeColor() {
      var _a, _b;

      if (((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.adminLevel) === ADMIN_LEVEL.PRAXISMANAGER) {
        return 'orange';
      }

      if (((_b = this.params.data) === null || _b === void 0 ? void 0 : _b.adminLevel) === ADMIN_LEVEL.CLIENT_ADMIN) {
        return 'red';
      }

      return 'grey lighten-1';
    }
  }
});