import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "color": "info",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.editItem
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("fas fa-pen")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Bearbeiten")])]), _c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "color": "info",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.cloneItem
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("fas fa-clone")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Duplizieren")])]), _c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "color": "error",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.deleteItem
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("fas fa-trash")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Löschen")])])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };