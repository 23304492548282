var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.mfaType === _vm.IMfaType.MAIL ? _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "E-Mail Login aktiviert",
      expression: "\"E-Mail Login aktiviert\""
    }],
    staticClass: "mfaType"
  }, [_c('i-far-envelope'), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("E-Mail")])], 1) : _vm.mfaType === _vm.IMfaType.AUTHENTICATOR ? _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Authenticator App aktiviert",
      expression: "\"Authenticator App aktiviert\""
    }],
    staticClass: "mfaType"
  }, [_c('i-far-mobile'), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("App")])], 1) : _vm._e(), _c('div', {
    staticClass: "mr-2"
  }), _vm.mfaEnforced ? _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "2FA verpflichtet",
      expression: "\"2FA verpflichtet\""
    }],
    staticClass: "mfaEnforced"
  }, [_c('i-far-lock', {
    staticClass: "mr-1"
  }), _c('span', [_vm._v("verpflichtet")])], 1) : _vm._e()]);
};

var staticRenderFns = [];
export { render, staticRenderFns };