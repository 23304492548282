var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "lb"
  }, [_c('v-popover', {
    staticClass: "lb-tooltip",
    "class": {
      "align-right": _vm.params.displaySumInsteadOfBehandler
    },
    attrs: {
      "trigger": "hover",
      "autoHide": false
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('div', {
          staticClass: "lb-box"
        }, [_c('div', {
          staticClass: "title p-2"
        }, [_vm._v("Leistungserbringer")]), _c('div', {
          staticClass: "lb-popup"
        }, [_c('table', {
          staticClass: "table table-striped table-condensed"
        }, [_c('thead', [_c('tr', [_c('th', [_c('span', [_vm._v("Behandler")])]), _vm._l(_vm.valueColumns, function (c) {
          return _c('th', {
            key: c
          }, [_c('div', {
            "class": c
          }, [_vm._v(_vm._s(_vm.colNames[c] || c))])]);
        })], 2)]), _c('tbody', _vm._l(_vm.behandler, function (entry, i) {
          return _c('tr', {
            key: entry.id
          }, [_c('td', {
            staticClass: "behandler-cell"
          }, [_vm.behandler.length > 1 ? _c('div', {
            staticClass: "color-hint",
            style: {
              "background-color": _vm.chartColors[i]
            }
          }) : _vm._e(), _c('span', {
            staticClass: "beh-name"
          }, [_vm._v(_vm._s(entry.displayName))]), _c('span', {
            staticClass: "beh-id"
          }, [_vm._v("(" + _vm._s(entry.behandler) + ")")])]), _vm._l(_vm.valueColumns, function (c) {
            return _c('td', {
              key: c
            }, [_c('div', {
              staticClass: "value",
              "class": c
            }, [_vm._v(_vm._s(_vm._f("euro")(entry[c])))])]);
          })], 2);
        }), 0), _c('tfoot', [_c('tr', [_c('td', [_c('span', {
          staticClass: "gesamt"
        }, [_vm._v("Gesamt")]), _vm.behandler.length < _vm.behandlerCount ? _c('span', [_vm._v("*")]) : _vm._e()]), _vm._l(_vm.valueColumns, function (c) {
          return _c('td', {
            key: c
          }, [_c('div', {
            staticClass: "gesamt value",
            "class": c
          }, [_vm._v(_vm._s(_vm._f("euro")(_vm.totals[c])))])]);
        })], 2)])]), _vm.behandler.length > 1 ? _c('div', {
          staticClass: "chart"
        }, [_c('PieChart', {
          attrs: {
            "data": _vm.chartVals,
            "labels": _vm.chartLabels,
            "colors": _vm.chartBackgrounds,
            "styles": _vm.chartStyles,
            "showLabels": false
          }
        })], 1) : _vm._e(), _vm.behandler.length < _vm.behandlerCount ? _c('div', {
          staticClass: "limit-hint"
        }, [_c('div', {
          staticClass: "text-danger"
        }, [_vm._v("* Es werden die " + _vm._s(_vm.maxBehandlerTooltipCount) + " umsatzstärksten Leistungserbringer von insgesamt " + _vm._s(_vm.behandlerCount) + " angezeigt. Die Gesamtsumme bezieht sich auf alle Leistungserbringer.")])]) : _vm._e()])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "lb-title"
  }, [_vm._v(_vm._s(_vm.behandlerText))])])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };