var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "termin",
    "class": _vm.terminClass
  }, [_c('v-popover', {
    staticClass: "tooltip-target",
    "class": {
      notermine: !_vm.hasSomeTermine,
      problem: !_vm.hasSomeTermine
    },
    attrs: {
      "offset": "8",
      "trigger": "hover",
      "autoHide": false,
      "placement": "right"
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('div', {
          staticClass: "termin-popup"
        }, [!(_vm.folgetermine.length > 0 && _vm.offenetermine.length > 0) ? [_c('span', [_vm._v("Es liegen keine offenen oder Folgetermine vor")])] : _vm._e(), _vm.folgetermine.length > 0 ? [_c('div', {
          staticClass: "title"
        }, [_vm._v("Folge- und Offene-Termine")]), _c('div', {
          staticClass: "header page-header"
        }, [_vm._v("Folgetermine")]), _c('div', {
          staticClass: "termin-list"
        }, [_c('table', {
          staticClass: "table table-striped table-condensed"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Datum")]), _vm.hkpMode ? _c('th', [_vm._v("HKP-Termintyp")]) : _vm._e(), _c('th', [_vm._v("Beschreibung")])])]), _c('tbody', _vm._l(_vm.folgetermine, function (t) {
          return _c('tr', {
            key: t.extid,
            "class": {
              "problem-striped-red": t.nomatch,
              white: t.nomatch,
              header: t.nomatch
            }
          }, [_c('td', [_c('span', [_vm._v(_vm._s(_vm._f("tz")(t.tag, 'DD.MM.YYYY', 'CET')))])]), _vm.hkpMode ? _c('td', [t.hkptyp === 1 ? _c('span', [_vm._v("ZE")]) : _vm._e(), t.hkptyp === 2 ? _c('span', [_vm._v("PAR")]) : _vm._e(), t.hkptyp === 3 ? _c('span', [_vm._v("IMPLA")]) : _vm._e(), t.hkptyp !== 1 && t.hkptyp !== 2 && t.hkptyp !== 3 ? _c('span', [_vm._v("-")]) : _vm._e()]) : _vm._e(), _c('td', [_c('span', [_vm._v(_vm._s(t.kommentar || '-'))])])]);
        }), 0)])])] : _vm._e(), _vm.offenetermine.length > 0 ? [_c('div', {
          staticClass: "header-gap page-header"
        }, [_vm._v("Offene Termine")]), _c('div', {
          staticClass: "termin-list"
        }, _vm._l(_vm.offenetermine, function (t) {
          return _c('div', {
            staticClass: "kommentar"
          }, [_vm._v(_vm._s(t.kommentar || '-'))]);
        }), 0)] : _vm._e(), _c('div', {
          staticClass: "legende"
        }, [_c('div', {
          staticClass: "legende-item"
        }, [_c('div', {
          staticClass: "legende-item-icon"
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ["fas", "calendar-alt"]
          }
        })], 1), _c('div', {
          staticClass: "legende-item-text"
        }, [_vm._v("Hat Folgetermine")])]), _c('div', {
          staticClass: "legende-item"
        }, [_c('div', {
          staticClass: "legende-item-icon problem"
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ["fas", "calendar-alt"]
          }
        })], 1), _c('div', {
          staticClass: "legende-item-text"
        }, [_vm._v("Hat offene Termine")])]), _c('div', {
          staticClass: "legende-item"
        }, [_c('div', {
          staticClass: "legende-item-icon problem"
        }), _c('div', {
          staticClass: "legende-item-text"
        }, [_vm._v("Hat keine Folgetermine")])]), _vm.hkpMode ? _c('div', {
          staticClass: "legende-item"
        }, [_c('div', {
          staticClass: "legende-item-icon white problem-striped-red"
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ["fas", "calendar-alt"]
          }
        })], 1), _c('div', {
          staticClass: "legende-item-text"
        }, [_vm._v("HKP pflichtiger Termin ohne genehmigten HKP")])]) : _vm._e()])], 2)];
      },
      proxy: true
    }])
  }, [_vm.hasSomeTermine ? _c('font-awesome-icon', {
    staticClass: "mx-1 calendar-icon",
    "class": _vm.terminClass,
    attrs: {
      "icon": ["fas", "calendar-alt"]
    }
  }) : _c('div', {
    staticClass: "hover-target"
  })], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };