import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: _vm.tooltipText,
        html: true,
        classes: "tooltip-help",
        container: "body",
        boundariesElement: "body",
        placement: _vm.placement
      },
      expression: "{ content: tooltipText, html: true, classes: \"tooltip-help\", container: \"body\", boundariesElement: \"body\", placement: placement }"
    }],
    staticClass: "help-tooltip"
  }, [_c('div', {
    staticClass: "help-icon"
  }, [_c(VIcon, {
    staticClass: "icon",
    "class": _vm.iconClass,
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-info-circle")])], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };