import { VChip } from 'vuetify/lib/components/VChip';

import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex flex-wrap py-2"
  }, [_vm.views.length === 0 ? _c(VChip, {
    attrs: {
      "small": "",
      "color": "green",
      "dark": ""
    }
  }, [_c('span', [_vm._v("Alle Views")])]) : _vm._e(), _vm._l(_vm.views, function (view) {
    return _c(VChip, {
      key: view.id,
      staticClass: "mr-1 mb-1",
      attrs: {
        "small": "",
        "color": "info"
      }
    }, [_c('span', [_vm._v(_vm._s(view.name))])]);
  })], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };