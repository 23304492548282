var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('a', {
    on: {
      "click": _vm.copy
    }
  }, [_vm._v(_vm._s(_vm.params.value))])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };