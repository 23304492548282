/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ZaPzrCell.vue?vue&type=template&id=1d7a2460&scoped=true&lang=pug"
import script from "./ZaPzrCell.vue?vue&type=script&lang=ts"
export * from "./ZaPzrCell.vue?vue&type=script&lang=ts"
import style0 from "./ZaPzrCell.vue?vue&type=style&index=0&id=1d7a2460&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d7a2460",
  null
  
)

export default component.exports