import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c(VCard, [_c(VToolbar, {
    staticClass: "elevation-0",
    attrs: {
      "color": "grey lighten-3",
      "dark": ""
    }
  }, [_c(VIcon, {
    staticClass: "primary--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-cloud-arrow-up")]), _c(VToolbarTitle, {
    staticClass: "primary--text ml-2"
  }, [_vm._v("Import Informationen")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("fas fa-close")])], 1)], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
    staticClass: "pb-0 pl-0",
    attrs: {
      "cols": "3"
    }
  }, [_c(VSubheader, {
    staticClass: "font-weight-bold"
  }, [_vm._v("Patienten-IDs")])], 1), _c(VCol, {
    staticClass: "pb-0 pl-0",
    attrs: {
      "cols": "9"
    }
  }, [_c(VSubheader, {
    staticClass: "font-weight-bold"
  }, [_vm._v("Daten")])], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "3"
    }
  }, [_c(VList, {
    staticClass: "overflow-y-auto",
    attrs: {
      "dense": "",
      "max-height": "1000"
    }
  }, [_c(VListItemGroup, {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.selectedID,
      callback: function callback($$v) {
        _vm.selectedID = $$v;
      },
      expression: "selectedID"
    }
  }, _vm._l(_vm.patIds, function (id) {
    return _c(VListItem, {
      key: id,
      attrs: {
        "value": id
      }
    }, [_c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(id))])], 1)], 1);
  }), 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "9"
    }
  }, [_vm.importInfo ? _c(VRow, _vm._l(_vm.importInfo, function (pi) {
    return _c(VCol, {
      key: pi.where.key,
      attrs: {
        "cols": "12"
      }
    }, [_c(VCard, {
      staticClass: "fill-height",
      attrs: {
        "color": "grey lighten-5",
        "flat": ""
      }
    }, [_c(VCardTitle, [_c('h3', [_vm._v(_vm._s(pi.where.name))])]), _c(VCardText, _vm._l(pi.models, function (model) {
      return _c('div', {
        key: model.model,
        staticClass: "py-1"
      }, [model.extids.length > 0 ? _c('div', [_c('span', [_vm._v("Model")]), _c('span', {
        staticClass: "bold pl-1"
      }, [_vm._v(_vm._s(model.model))]), _c('span', [_vm._v("(" + _vm._s(model.extids.length) + "):")]), _c('div', {
        staticClass: "indent"
      }, _vm._l(model.extids, function (id) {
        return _c('span', {
          key: id
        }, [_vm._v(_vm._s(id) + ",")]);
      }), 0)]) : _vm._e(), model.extids.length <= 0 ? _c('div', [_c('span', [_vm._v("Keine Änderungen")])]) : _vm._e()]);
    }), 0)], 1)], 1);
  }), 1) : _vm._e()], 1)], 1)], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };