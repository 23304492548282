import { VChip } from 'vuetify/lib/components/VChip';

import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.stichwort.kuerzel ? [_c('div', {
    staticClass: "kuerzel-chip mr-1",
    style: {
      backgroundColor: _vm.stichwort.farbe
    }
  }, [_vm._v(_vm._s(_vm.stichwort.kuerzel))]), _c('span', {
    staticClass: "text-no-wrap"
  }, [_vm._v(_vm._s(_vm.stichwort.name))])] : [_vm.stichwort.name ? _c(VChip, {
    staticClass: "font-weight-bold mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(_vm._s(_vm.stichwort.name))]) : _vm._e()]], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };