var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _vm.params.data ? _c('div', {
    staticClass: "summary"
  }, [_c('div', {
    staticClass: "summary-header-row"
  }, [_c('div', {
    staticClass: "summary-title"
  }, [_vm._v(_vm._s(_vm.params.data.title))]), _c('div', {
    staticClass: "summary-count"
  }, [_vm._v(_vm._s(_vm.params.data.count) + " " + _vm._s(_vm.params.data.count === 1 ? 'Eintrag' : 'Einträge'))])])]) : _vm._e();
};

var staticRenderFns = [];
export { render, staticRenderFns };