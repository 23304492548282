var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "mahnstufe"
  }, [_vm.mahnstufe ? _c('v-popover', {
    staticClass: "tooltip-target",
    attrs: {
      "trigger": "click",
      "autoHide": false
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_vm.mahnungen ? _c('div', {
          staticClass: "lb-popup"
        }, [_c('div', {
          staticClass: "title mb-1"
        }, [_vm._v("Mahnungen")]), _vm.mahnungen.erstemahnung ? _c('div', {
          staticClass: "lbtlrow"
        }, [_c('div', {
          staticClass: "key"
        }, [_c('span', [_vm._v("1. Mahnung")])]), _c('div', {
          staticClass: "value"
        }, [_c('span', {
          staticClass: "datum"
        }, [_vm._v(_vm._s(_vm._f("date")(_vm.mahnungen.erstemahnung)))])])]) : _vm._e(), _vm.mahnungen.zweitemahnung ? _c('div', {
          staticClass: "lbtlrow"
        }, [_c('div', {
          staticClass: "key"
        }, [_c('span', [_vm._v("2. Mahnung")])]), _c('div', {
          staticClass: "value"
        }, [_c('span', {
          staticClass: "datum"
        }, [_vm._v(_vm._s(_vm._f("date")(_vm.mahnungen.zweitemahnung)))])])]) : _vm._e(), _vm.mahnungen.drittemahnung ? _c('div', {
          staticClass: "lbtlrow"
        }, [_c('div', {
          staticClass: "key"
        }, [_c('span', [_vm._v("3. Mahnung")])]), _c('div', {
          staticClass: "value"
        }, [_c('span', {
          staticClass: "datum"
        }, [_vm._v(_vm._s(_vm._f("date")(_vm.mahnungen.drittemahnung)))])])]) : _vm._e(), _vm.mahnungen.anwalt == "A" ? _c('div', {
          staticClass: "lbtlrow"
        }, [_c('div', {
          staticClass: "key"
        }, [_c('span', {
          staticClass: "anwalt"
        }, [_vm._v("Anwalt")])]), _c('div', {
          staticClass: "value"
        }, [_c('span', {
          staticClass: "datum"
        })])]) : _vm._e()]) : _vm._e()];
      },
      proxy: true
    }], null, false, 1871210534)
  }, [_c('div', {
    staticClass: "div"
  }, [_vm._v(_vm._s(_vm.mahnstufe))])]) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };