import ActionButtonsCell from '@/components/cells/ActionButtonsCell.vue';
import BehandlerCell from '@/components/cells/BehandlerCell.vue';
import CheckAndCrossCell from '@/components/cells/CheckAndCrossCell.vue';
import CheckboxCell from '@/components/cells/CheckboxCell.vue';
import ColorPickerCell from '@/components/cells/ColorPickerCell.vue';
import ConditionsCell from '@/components/cells/ConditionsCell.vue';
import CounterCell from '@/components/cells/CounterCell.vue';
import DateSelectCell from '@/components/cells/DateSelectCell.vue';
import DeleteButtonCell from '@/components/cells/DeleteButtonCell.vue';
import DropdownCell from '@/components/cells/DropdownCell.vue';
import EditButtonCell from '@/components/cells/EditButtonCell.vue';
import EinstellungenCell from '@/components/cells/EinstellungenCell.vue';
import AvatarCell from '@/components/cells/AvatarCell.vue';
import HelpTooltipCell from '@/components/cells/HelpTooltipCell.vue';
import IconsCell from '@/components/cells/IconsCell.vue';
import ImportIdCell from '@/components/cells/ImportIdCell.vue';
import InputCell from '@/components/cells/InputCell.vue';

import LeistungenInfoCell from '@/components/cells/LeistungenInfoCell.vue';
import LeistungserbringerCell from '@/components/cells/LeistungserbringerCell.vue';
import LoadingCell from '@/components/cells/LoadingCell.vue';
import MahnstufeInfoCell from '@/components/cells/MahnstufeInfoCell.vue';
import PatientCell from '@/components/cells/PatientCell.vue';
import AARegelActiveCell from '@/components/cells/AARegelActiveCell.vue';
import AAIgnoreUeberweiserCell from '@/components/cells/AAIgnoreUeberweiserCell.vue';
import StichwortCell from '@/components/cells/StichwortCell.vue';
import StichworteCell from '@/components/cells/StichworteCell.vue';
import TeamMitgliederCell from '@/components/cells/TeamMitgliederCell.vue';
import TerminBehandlerCell from '@/components/cells/TerminBehandlerCell.vue';
import TermineCell from '@/components/cells/TermineCell.vue';
import TermingruppeCell from '@/components/cells/TermingruppeCell.vue';
import ToggleButtonsCell from '@/components/cells/ToggleButtonsCell.vue';
import UserViewsCell from '@/components/cells/UserViewsCell.vue';
import TwoFaCell from '@/components/cells/TwoFaCell.vue';
import ZaPzrCell from '@/components/cells/ZaPzrCell.vue';
import LeistungenSummary from '../summaries/LeistungenSummary.vue';
import PatientenUmsaetzeSummary from '../summaries/PatientenUmsaetzeSummary.vue';
import RechnungenSummary from '../summaries/RechnungenSummary.vue';
import TabletActionButtonsCell from '@/components/cells/TabletActionButtonsCell.vue';
import TabletNameCell from '@/components/cells/TabletNameCell.vue';
import CopyCell from '@/components/cells/CopyCell.vue';
import BehandlerIdCell from '@/components/cells/BehandlerIdCell.vue';
import ImportPatIdsCell from '@/components/cells/ImportPatIdsCell.vue';
import DateRangeCell from '@/components/cells/DateRangeCell.vue';
import DownloadCell from '@/components/cells/DownloadCell.vue';
import ActionIconsCell from '@/components/cells/ActionIconsCell.vue';
import HelpTooltipHead from '@/components/headers/HelpTooltipHead.vue';
import ExternalAccessCell from '@/components/cells/ExternalAccessCell.vue';
import EditPatidCell from '@/components/cells/EditPatidCell.vue';

export const customCellRenderers = {
  AARegelActiveCell,
  AAIgnoreUeberweiserCell,
  StichworteCell,
  EinstellungenCell,
  BehandlerCell,
  BehandlerIdCell,
  TerminBehandlerCell,
  ZaPzrCell,
  ColorPickerCell,
  DropdownCell,
  IconsCell,
  TeamMitgliederCell,
  AvatarCell,
  HelpTooltipHead,
  DateSelectCell,
  CheckboxCell,
  CheckAndCrossCell,
  CopyCell,
  StichwortCell,
  InputCell,
  DeleteButtonCell,
  ActionButtonsCell,
  HelpTooltipCell,
  ConditionsCell,
  CounterCell,
  UserViewsCell,
  TwoFaCell,
  LeistungenInfoCell,
  LeistungserbringerCell,
  LoadingCell,
  PatientCell,
  TermineCell,
  LeistungenSummary,
  PatientenUmsaetzeSummary,
  MahnstufeInfoCell,
  RechnungenSummary,
  ToggleButtonsCell,
  TermingruppeCell,
  EditButtonCell,
  ImportIdCell,
  ImportPatIdsCell,
  TabletActionButtonsCell,
  TabletNameCell,
  DateRangeCell,
  DownloadCell,
  ActionIconsCell,
  ExternalAccessCell,
  EditPatidCell,
};
