var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "wrap"
  }, [_c('span', {
    staticClass: "clear",
    on: {
      "click": function click($event) {
        return _vm.remove();
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mx-1",
    attrs: {
      "size": "sm",
      "icon": ["fas", "trash"]
    }
  })], 1), _c('DatePicker', {
    attrs: {
      "dateRange": _vm.dateRange,
      "singleDatePickerProp": true,
      "hideRanges": true,
      "autoOpen": true,
      "hideCalendarIcon": true,
      "opens": "left"
    },
    on: {
      "date-update": _vm.selectDate
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };