import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "text-center"
  }, [_vm.condition && _vm.conditions ? _c(VSelect, {
    attrs: {
      "items": _vm.conditions,
      "item-text": "value",
      "item-value": "key",
      "hide-details": "",
      "dense": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "primary--text"
        }, [_vm._v(_vm._s(item.value))])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "primary--text"
        }, [_vm._v(_vm._s(item.value))])];
      }
    }], null, false, 3601708092),
    model: {
      value: _vm.condition,
      callback: function callback($$v) {
        _vm.condition = $$v;
      },
      expression: "condition"
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };