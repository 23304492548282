/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AvatarCell.vue?vue&type=template&id=79ca4745&scoped=true&lang=pug"
import script from "./AvatarCell.vue?vue&type=script&lang=ts"
export * from "./AvatarCell.vue?vue&type=script&lang=ts"
import style0 from "./AvatarCell.vue?vue&type=style&index=0&id=79ca4745&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ca4745",
  null
  
)

export default component.exports