import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';

import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c(VBtnToggle, {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.item,
      callback: function callback($$v) {
        _vm.item = $$v;
      },
      expression: "item"
    }
  }, _vm._l(_vm.buttons, function (b) {
    return _c(VBtn, {
      key: b.name,
      attrs: {
        "small": "",
        "value": b.value,
        "color": b.color,
        "outlined": b.outlined
      }
    }, [_c('span', [_vm._v(_vm._s(b.name))]), b && b.help ? _c('HelpTooltip', {
      staticClass: "ml-1",
      attrs: {
        "text": b.help
      }
    }) : _vm._e()], 1);
  }), 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };