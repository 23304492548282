import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "tablet-name d-flex justify-center align-center"
  }, [_c(VAvatar, {
    attrs: {
      "size": "12",
      "color": _vm.color,
      "tile": !_vm.online,
      "left": "",
      "title": _vm.tokeninfo
    }
  }), _c(VTextField, {
    staticClass: "ml-2",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "focus": _vm.onFocus,
      "blur": _vm.onBlur,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.save.apply(null, arguments);
      }
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "color": "primary",
      "icon": "",
      "small": "",
      "disabled": !_vm.isMutated,
      "right": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-floppy-disk")])], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };