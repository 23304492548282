import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c(VTextField, {
    attrs: {
      "value": _vm.date,
      "rules": _vm.rules,
      "type": "date",
      "outlined": "",
      "flat": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "blur": _vm.commit
    }
  });
};

var staticRenderFns = [];
export { render, staticRenderFns };