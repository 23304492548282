import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { authStore } from '@/state/authStore';
import { defineComponent } from 'vue';
import { chain } from 'lodash';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    views: function views() {
      var _this = this;

      return chain(authStore.getters.viewsAvailable).filter(function (v) {
        var _a, _b;

        return !!((_b = (_a = _this.params.data) === null || _a === void 0 ? void 0 : _a.views) === null || _b === void 0 ? void 0 : _b.includes(v.id));
      }).sortBy('name').value();
    }
  }
});