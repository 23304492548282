import "core-js/modules/es.array.map.js";
import { defineComponent } from 'vue';
import BehandlerChip from '@/components/BehandlerChip.vue';
import { behandlerStore } from '@/state/behandlerStore';
export default defineComponent({
  components: {
    BehandlerChip: BehandlerChip
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    behandler: function behandler() {
      return this.params.value ? behandlerStore.getters.map[this.params.value] : undefined;
    },
    isAutoConfigured: function isAutoConfigured() {
      return false;
    }
  }
});