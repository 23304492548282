var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex py-2 overflow-hidden"
  }, [_c('BehandlerChip', {
    staticClass: "overflow-hidden",
    attrs: {
      "behandler": _vm.behandler,
      "is-autoconfigured": _vm.isAutoConfigured,
      "in-table": ""
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };