import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c(VBtn, {
    attrs: {
      "color": "info",
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.editItem
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fas fa-pen")])], 1), _c(VBtn, {
    attrs: {
      "color": "red",
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.deleteItem
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("fas fa-trash")])], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };