var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd",
      "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape",
      "width": "210mm",
      "height": "297mm",
      "viewBox": "189.5 77 147.5 147",
      "id": "rose-o",
      "version": "1.1",
      "inkscape:version": "0.91 r13725",
      "sodipodi:docname": "rose_o.svg"
    }
  }, [_c('defs', {
    attrs: {
      "id": "defs4"
    }
  }), _c('sodipodi:namedview', {
    attrs: {
      "id": "base",
      "pagecolor": "#ffffff",
      "bordercolor": "#666666",
      "borderopacity": "1.0",
      "inkscape:pageopacity": "0.0",
      "inkscape:pageshadow": "2",
      "inkscape:zoom": "0.61670782",
      "inkscape:cx": "372.04724",
      "inkscape:cy": "526.1811",
      "inkscape:document-units": "px",
      "inkscape:current-layer": "g_rose",
      "showgrid": "false",
      "showguides": "true",
      "inkscape:guide-bbox": "true",
      "inkscape:window-width": "1440",
      "inkscape:window-height": "851",
      "inkscape:window-x": "0",
      "inkscape:window-y": "1",
      "inkscape:window-maximized": "1"
    }
  }), _c('g', {
    attrs: {
      "inkscape:groupmode": "layer",
      "id": "g_rose_o"
    }
  }, [_c('g', {
    attrs: {
      "id": "g_smallbar",
      "transform": "matrix(3.0329521,0,0,-3.0329521,233.23991,142.58059)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#fff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c 0,0 -1,0 -1,-1 l 0,-12.705 c 0,0 0,-1 1,-1 l 3.852,0 c 0,0 1,0 1,1 l 0,12.705 c 0,0 0,1 -1,1 L 0,0 Z",
      "id": "p_smallbar",
      "inkscape:connector-curvature": "0"
    }
  })]), _c('g', {
    attrs: {
      "id": "g_mediumbar",
      "transform": "matrix(3.0329521,0,0,-3.0329521,257.55222,131.93796)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#fff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c 0,0 -1,0 -1,-1 l 0,-16.214 c 0,0 0,-1 1,-1 l 3.852,0 c 0,0 1,0 1,1 l 0,16.214 c 0,0 0,1 -1,1 L 0,0 Z",
      "id": "p_mediumbar",
      "inkscape:connector-curvature": "0"
    }
  })]), _c('g', {
    attrs: {
      "id": "g_bigbar",
      "transform": "matrix(3.0329521,0,0,-3.1034376,281.86451,118.0697)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#fff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c 0,0 -1,0 -1,-1 l 0,-20.269 c 0,0 0,-1 1,-1 l 3.852,0 c 0,0 1,0 1,1 l 0,20.269 c 0,0 0,1 -1,1 L 0,0 Z",
      "id": "p_bigbar",
      "inkscape:connector-curvature": "0"
    }
  })])])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };