import { ADMIN_LEVEL, FEATURES, IUIUser, R4CFEATURES, FACTORINGFEATURES, ANAMNESEFEATURES } from '@/../../types';
import { RouteConfigSingleView } from 'vue-router/types/router';

function lazyLoad(view: string) {
  return () => import(`@/views/${view}.vue`);
}

export interface ISettingsRouteMeta {
  displayName?: string;
  requiredFeatures?: FEATURES[];

  checkViewAccess?(user: IUIUser): boolean;
}

// enforce typing for route meta and require name
export type SettingsRouteConfig = Omit<RouteConfigSingleView, 'meta'> & { name: string; meta?: ISettingsRouteMeta };

export const editableSettingsRoutes: SettingsRouteConfig[] = [
  {
    path: 'daten',
    name: 'Daten',
    component: lazyLoad('Settings/Daten'),
    meta: {
      displayName: 'Praxis',
      requiredFeatures: [FEATURES.METRICS, ...R4CFEATURES],
    },
  },
  {
    path: 'leistungen',
    name: 'Leistungen',
    component: lazyLoad('Settings/Leistungen'),
    meta: {
      requiredFeatures: [FEATURES.METRICS],
    },
  },
  {
    path: 'terminartZuordnung',
    name: 'TerminartZuordnung',
    component: lazyLoad('Settings/TerminartZuordnung'),
    meta: {
      displayName: 'Terminart-Zuordnung',
      requiredFeatures: [FEATURES.METRICS],
    },
  },
  {
    path: 'behandlerTeams',
    name: 'BehandlerTeams',
    component: lazyLoad('Settings/BehandlerTeams'),
    meta: {
      displayName: 'Behandler/Teams',
      requiredFeatures: [FEATURES.METRICS],
    },
  },
  {
    path: 'heute',
    name: 'Heute',
    component: lazyLoad('Settings/Heute'),
    meta: {
      requiredFeatures: [FEATURES.METRICS],
    },
  },
  {
    path: 'datensammlung',
    name: 'Datensammlung',
    component: lazyLoad('Settings/Datensammlung'),
    meta: {
      requiredFeatures: [FEATURES.METRICS],
    },
  },
  {
    path: 'abrechnung',
    name: 'Abrechnung',
    component: lazyLoad('Settings/Abrechnung'),
    meta: {
      requiredFeatures: [FEATURES.METRICS],
    },
  },

  {
    path: 'factoring',
    name: 'Factoring',
    component: lazyLoad('Settings/Factoring'),
    meta: {
      requiredFeatures: FACTORINGFEATURES,
    },
  },
  {
    path: 'anamnese',
    name: 'Anamnese',
    component: lazyLoad('Settings/Anamnese/AnamneseSettings'),
    meta: {
      requiredFeatures: ANAMNESEFEATURES,
    },
  },
  {
    path: 'workflows',
    name: 'Workflows',
    component: lazyLoad('Settings/Anamnese/WorkflowSettings'),
    meta: {
      requiredFeatures: ANAMNESEFEATURES,
    },
  },

  {
    path: '*',
    name: 'SettingsRedirect',
    redirect: { name: 'Daten' },
  },
  {
    path: '',
    name: 'SettingsRedirect2',
    redirect: { name: 'Daten' },
  },
];

export const settingsRoutes: RouteConfigSingleView[] = [
  {
    path: 'import',
    name: 'Import',
    component: lazyLoad('Settings/Import'),
    meta: {
      requiredFeatures: [FEATURES.METRICS],
    },
  },
  {
    path: 'benutzer',
    name: 'Benutzer',
    component: lazyLoad('Settings/Benutzer'),
    meta: {
      checkViewAccess(user: IUIUser) {
        return user.adminLevel >= ADMIN_LEVEL.CLIENT_ADMIN;
      },
    },
  },
  {
    path: 'tablets',
    name: 'Tablets',
    component: lazyLoad('Settings/Tablets'),
    meta: {
      requiredFeatures: R4CFEATURES,
    },
  },
  {
    path: 'vertrag',
    name: 'Vertrag',
    component: lazyLoad('Settings/Vertrag'),
  },
  {
    path: '',
    component: lazyLoad('Settings/EditableSettingsLayout'),
    children: editableSettingsRoutes,
  },
];
