import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex"
  }, [_vm.params.node.id ? _c('span', [_vm._v(_vm._s(_vm.params.valueFormatted || _vm.params.value))]) : _c(VProgressCircular, {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "16",
      "width": "3"
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };