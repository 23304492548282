import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex"
  }, [_vm.params.node.id && _vm.params.value ? _c(VIcon, {
    attrs: {
      "color": _vm.checkColor,
      "dense": ""
    }
  }, [_vm._v("fas fa-check")]) : _vm.params.node.id && !_vm.params.value ? _c(VIcon, {
    attrs: {
      "color": _vm.timesColor,
      "dense": ""
    }
  }, [_vm._v("fas fa-times")]) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };