var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "wrap-loader"
  }, [_c('div', {
    staticClass: "center-loader"
  }, [!_vm.authStoreState.isCharlyAnalytics ? _c('svg', {
    attrs: {
      "xmlns:svg": "http://www.w3.org/2000/svg",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "60 50 400 200"
    }
  }, [_c('g', {
    attrs: {
      "inkscape:groupmode": "layer",
      "id": "g_rose"
    }
  }, [_c('g', {
    attrs: {
      "id": "g_o",
      "transform": "matrix(3.0329521,0,0,-3.0329521,200.81675,88.117166)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#009ee0",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c -2.352,-2.352 -3.527,-5.199 -3.527,-8.545 l 0,-23.997 c 0,-3.345 1.175,-6.194 3.527,-8.545 2.351,-2.352 5.199,-3.527 8.545,-3.527 l 23.997,0 c 3.345,0 6.193,1.175 8.545,3.527 2.351,2.351 3.527,5.2 3.527,8.545 l 0,23.997 c 0,3.346 -1.176,6.193 -3.527,8.545 -2.352,2.351 -5.2,3.527 -8.545,3.527 l -23.997,0 C 5.199,3.527 2.351,2.351 0,0 m 35.378,-5.709 c 0.775,-0.776 1.164,-1.721 1.164,-2.836 l 0,-23.997 c 0,-1.116 -0.389,-2.061 -1.164,-2.836 -0.776,-0.776 -1.721,-1.164 -2.836,-1.164 l -23.997,0 c -1.116,0 -2.062,0.388 -2.836,1.164 -0.777,0.775 -1.164,1.72 -1.164,2.836 l 0,23.997 c 0,1.115 0.387,2.06 1.164,2.836 0.774,0.775 1.72,1.164 2.836,1.164 l 23.997,0 c 1.115,0 2.06,-0.389 2.836,-1.164",
      "id": "p_o",
      "inkscape:connector-curvature": "0"
    }
  })]), _c('g', {
    attrs: {
      "transform": "translate(225, 188) scale(1, -1)"
    }
  }, [_c('rect', {
    staticClass: "bar_small",
    attrs: {
      "rx": "2",
      "ry": "2",
      "x": "4",
      "y": "0",
      "width": "18",
      "height": "30"
    }
  }), _c('rect', {
    staticClass: "bar_medium",
    attrs: {
      "rx": "2",
      "ry": "2",
      "x": "29",
      "y": "0",
      "width": "18",
      "height": "30"
    }
  }), _c('rect', {
    staticClass: "bar_big",
    attrs: {
      "rx": "2",
      "ry": "2",
      "x": "55",
      "y": "0",
      "width": "18",
      "height": "30"
    }
  })])])]) : _vm._e(), _vm.authStoreState.isCharlyAnalytics ? _c('img', {
    staticClass: "charlyLoadingImg",
    attrs: {
      "src": require("../assets/charly_analytics_fade_circle.gif")
    }
  }) : _vm._e(), _c('p', {
    staticClass: "center-block loader-text"
  }, [_vm._v(_vm._s(_vm.loadingText))]), _vm.showSantaHat ? _c('font-awesome-icon', {
    staticClass: "santaHatIcon",
    "class": {
      isCharlyAnalytics: _vm.authStoreState.isCharlyAnalytics
    },
    attrs: {
      "icon": ['fad', 'hat-santa']
    }
  }) : _vm._e(), _vm.showEasterBunny ? _c('div', {
    staticClass: "easter",
    "class": {
      isCharlyAnalytics: _vm.authStoreState.isCharlyAnalytics
    }
  }, [_c('font-awesome-icon', {
    staticClass: "bunny",
    attrs: {
      "icon": ['fad', 'rabbit']
    }
  }), _c('div', {
    staticClass: "eggs"
  }, [_c('font-awesome-icon', {
    staticClass: "yellowEgg",
    attrs: {
      "icon": ['fad', 'egg']
    }
  }), _c('font-awesome-icon', {
    staticClass: "redEgg",
    attrs: {
      "icon": ['fad', 'egg']
    }
  }), _c('font-awesome-icon', {
    staticClass: "blueEgg",
    attrs: {
      "icon": ['fad', 'egg']
    }
  })], 1)], 1) : _vm._e()], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };