import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.array.includes.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return !_vm.selectedPar ? _c('div', {
    staticClass: "nothing"
  }) : _c('v-popover', {
    staticClass: "par-info tooltip-target",
    attrs: {
      "offset": "0",
      "trigger": "click",
      "autoHide": false,
      "placement": "right"
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('div', {
          staticClass: "par-popup"
        }, [_c('div', {
          staticClass: "par-header"
        }, [_c('div', {
          staticClass: "par-header-title"
        }, [_c('div', {
          staticClass: "par-header-title-main"
        }, [_c('span', {
          staticClass: "bold"
        }, [_vm._v("PAR-Therapie")]), _c('span', [_vm._v("Grad " + _vm._s(_vm.selectedPar.parstatus.grad))])]), _c('div', {
          staticClass: "par-header-title-phase"
        }, [_vm._v("(" + _vm._s(_vm._f("parPhase")(_vm.selectedPar.parstatus.phase)) + ")")]), _vm.selectedView === "PAR" && _vm.selectedPar.fehleranzahl.par > 0 ? _c('div', {
          staticClass: "par-header-title-error"
        }, [_vm.selectedPar.fehleranzahl.par > 1 ? _c('span', [_vm._v(_vm._s(_vm.selectedPar.fehleranzahl.par) + " Fehlerhinweise")]) : _vm._e(), _vm.selectedPar.fehleranzahl.par === 1 ? _c('span', [_vm._v("1 Fehlerhinweis")]) : _vm._e()]) : _vm._e(), _vm.selectedView === "CPT" && _vm.selectedPar.fehleranzahl.cpt > 0 ? _c('div', {
          staticClass: "par-header-title-error"
        }, [_vm.selectedPar.fehleranzahl.upt > 1 ? _c('span', [_vm._v(_vm._s(_vm.selectedPar.fehleranzahl.upt) + " Fehlerhinweise")]) : _vm._e(), _vm.selectedPar.fehleranzahl.upt === 1 ? _c('span', [_vm._v("1 Fehlerhinweis")]) : _vm._e()]) : _vm._e(), _vm.selectedView === "UPT" && _vm.selectedPar.fehleranzahl.upt > 0 ? _c('div', {
          staticClass: "par-header-title-error"
        }, [_vm.selectedPar.fehleranzahl.upt > 1 ? _c('span', [_vm._v(_vm._s(_vm.selectedPar.fehleranzahl.upt) + " Fehlerhinweise")]) : _vm._e(), _vm.selectedPar.fehleranzahl.upt === 1 ? _c('span', [_vm._v("1 Fehlerhinweis")]) : _vm._e()]) : _vm._e(), _c('div', {
          staticClass: "par-header-title-patid",
          on: {
            "click": function click($event) {
              return _vm.copy(_vm.selectedPar.patid);
            }
          }
        }, [_c('span', [_vm._v("Pat: " + _vm._s(_vm.selectedPar.patid))])]), _vm.par && _vm.par.length > 1 ? _c('div', {
          staticClass: "par-header-title-hkpid"
        }, [_c('div', [_vm._v("HKP (" + _vm._s(_vm.par.length) + "):")]), _c('select', {
          staticClass: "par-select",
          on: {
            "change": function change($event) {
              return _vm.onChange($event);
            }
          }
        }, _vm._l(_vm.par, function (p) {
          return _c('option', {
            key: p.parstatus.hkp,
            domProps: {
              "value": p.parstatus.hkp
            }
          }, [_vm._v(_vm._s(_vm._f("tz")(p.von, 'DD.MM.YYYY', 'CET')) + " - " + _vm._s(_vm._f("tz")(p.bis, 'DD.MM.YYYY', 'CET')) + " (" + _vm._s(p.parstatus.hkp) + ")")]);
        }), 0)]) : _c('div', {
          staticClass: "par-header-title-hkpid",
          on: {
            "click": function click($event) {
              return _vm.copy(_vm.selectedPar.parstatus.hkp);
            }
          }
        }, [_c('div', [_vm._v("HKP: " + _vm._s(_vm.selectedPar.parstatus.hkp))])])]), _vm.showStichworte ? _c('div', {
          staticClass: "par-header-stichworte"
        }, _vm._l(_vm.stichworte, function (s) {
          return _c('div', {
            key: s.kuerzel,
            staticClass: "stichwort"
          }, [_vm._v(_vm._s(s.kuerzel))]);
        }), 0) : _vm._e(), !_vm.showStichworte ? _c('div', {
          staticClass: "notting"
        }) : _vm._e(), _c('div', {
          staticClass: "par-header-buttons"
        }, [_c(VBtnToggle, {
          attrs: {
            "mandatory": ""
          },
          model: {
            value: _vm.selectedView,
            callback: function callback($$v) {
              _vm.selectedView = $$v;
            },
            expression: "selectedView"
          }
        }, [_c(VBtn, {
          attrs: {
            "value": _vm.PAREreignisPhaseTyp.PAR,
            "small": "",
            "active-class": "primary"
          }
        }, [_c('span', [_vm._v("PAR")]), _vm.selectedPar.fehleranzahl.par > 0 ? _c('span', {
          staticClass: "badge",
          "class": {
            "badge-error": _vm.selectedView === "PAR",
            "badge-error-invert": _vm.selectedView !== "PAR"
          }
        }, [_vm._v(_vm._s(_vm.selectedPar.fehleranzahl.par))]) : _vm._e()]), _vm.selectedPar.showcpt ? _c(VBtn, {
          attrs: {
            "value": _vm.PAREreignisPhaseTyp.CPT,
            "small": "",
            "active-class": "primary"
          }
        }, [_c('span', [_vm._v("CPT")]), _vm.selectedPar.fehleranzahl.cpt > 0 ? _c('span', {
          staticClass: "badge",
          "class": {
            "badge-error": _vm.selectedView === "CPT",
            "badge-error-invert": _vm.selectedView !== "CPT"
          }
        }, [_vm._v(_vm._s(_vm.selectedPar.fehleranzahl.cpt))]) : _vm._e()]) : _vm._e(), _c(VBtn, {
          attrs: {
            "type": "button",
            "value": _vm.PAREreignisPhaseTyp.UPT,
            "disabled": _vm.selectedPar.disableupt,
            "small": "",
            "active-class": "primary"
          }
        }, [_c('span', [_vm._v("UPT")]), _vm.selectedPar.fehleranzahl.upt > 0 ? _c('span', {
          staticClass: "badge",
          "class": {
            "badge-error": _vm.selectedView === "UPT",
            "badge-error-invert": _vm.selectedView !== "UPT"
          }
        }, [_vm._v(_vm._s(_vm.selectedPar.fehleranzahl.upt))]) : _vm._e()])], 1)], 1)]), _c('div', {
          staticClass: "par-content"
        }, [_vm.selectedView === "CPT" ? _c(VCard, {
          attrs: {
            "flat": "",
            "tile": ""
          }
        }, [_vm.selectedPar.cptPlanStatus === "NUR_ANTRAG" ? _c('div', {
          staticClass: "cpt-hint"
        }, [_c('span', [_vm._v("Es sind CPT Leistungen geplant:")]), _c('div', {
          staticClass: "cpt-count"
        }, [_vm._v(_vm._s(_vm.selectedPar.parstatus.cpta))]), _c('div', {
          staticClass: "cpt-typ"
        }, [_vm._v("x CPTa")]), _c('div', {
          staticClass: "cpt-count"
        }, [_vm._v(_vm._s(_vm.selectedPar.parstatus.cptb))]), _c('div', {
          staticClass: "cpt-typ"
        }, [_vm._v("x CPTb")])]) : _vm._e(), _vm.selectedPar.cptPlanStatus === "KAPUTT_WEGEN_UPT_LEISTUNGEN" ? _c('div', {
          staticClass: "cpt-hint"
        }, [_c('span', [_vm._v("Es wurden CPT Leistungen geplant:")]), _c('div', {
          staticClass: "cpt-count"
        }, [_vm._v(_vm._s(_vm.selectedPar.parstatus.cpta))]), _c('div', {
          staticClass: "cpt-typ"
        }, [_vm._v("x CPTa")]), _c('div', {
          staticClass: "cpt-count"
        }, [_vm._v(_vm._s(_vm.selectedPar.parstatus.cptb))]), _c('div', {
          staticClass: "cpt-typ"
        }, [_vm._v("x CPTb")]), _c('div', {
          staticClass: "cpt-warn"
        }, [_vm._v("Aber es wurden schon UPT Leistungen erbracht, es dürfen keine CPT-Leistungen mehr abgerechnet werden.")])]) : _vm._e()]) : _vm._e(), _vm._l(_vm.parGruppenFiltered, function (g) {
          return _c('div', {
            key: g.name,
            staticClass: "par-gruppe",
            "class": {
              "zeit-aktuell": g.zeit === "AKTUELL",
              "zeit-vergangen": g.zeit === "VERGANGEN",
              "zeit-zukunft": g.zeit === "ZUKUNFT",
              "cpt-gruppe": _vm.selectedView === "CPT"
            }
          }, [g.name ? _c('div', {
            staticClass: "par-gruppe-title"
          }, [_c('div', {
            staticClass: "par-gruppe-title-name"
          }, [_vm._v(_vm._s(g.name))]), !g.group.startsWith("UPT") ? _c('div', {
            staticClass: "par-gruppe-title-interval"
          }, [_vm._v(": " + _vm._s(_vm._f("tz")(g.startCalendar || g.start, 'DD.MM.YYYY', 'CET')) + " - " + _vm._s(_vm._f("tz")(g.endeCalendar || g.ende, 'DD.MM.YYYY', 'CET')))]) : _vm._e(), g.group.startsWith("UPT") ? _c('div', {
            staticClass: "notting"
          }) : _vm._e(), _c('div', {
            staticClass: "notting"
          }), g.zeit === "AKTUELL" ? _c('div', {
            staticClass: "interval-hint"
          }, [_vm._v("Aktuelles Intervall")]) : _vm._e()]) : _vm._e(), !g.name ? _c('div', {
            staticClass: "par-gruppe-title"
          }, [_vm._v(_vm._s(g.group) + ": " + _vm._s(_vm._f("tz")(g.startCalendar || g.start, 'DD.MM.YYYY', 'CET')) + " - " + _vm._s(_vm._f("tz")(g.endeCalendar || g.ende, 'DD.MM.YYYY', 'CET')))]) : _vm._e(), _vm._l(g.ereignisse, function (e) {
            return _c(VCard, {
              key: e.tag + e.typ,
              attrs: {
                "flat": "",
                "tile": ""
              }
            }, [!["UPTTERMINZEITMARKERSTART", "UPTTERMINZEITMARKERENDE", "ENDEPANBEHANDLUNGSZEITMARKER", "ENDECPTBEHANDLUNGSZEITMARKER", "CPTNURANTRAG", "UPTGMARKER", "ENDMARKER", "MOREUPTS", "MISSINGUPT", "MISSINGBEV", "MISSINGPA", "BEVINTERVALSTART", "BEVINTERVALENDE", "HKPVERLAENGERTAM", "HKPVERLAENGERTBIS"].includes(e.typ) ? _c('div', {
              staticClass: "par-ereignis",
              "class": {
                "hkp-status": e.typ === "HKPSTATUS",
                "upt-termin-nach-ende": e.uptTyp === "TERMINNACHENDE"
              },
              attrs: {
                "title": e.typ
              }
            }, [_c('div', {
              staticClass: "par-ereignis-datum"
            }, [_vm._v(_vm._s(_vm._f("tz")(e.tag, 'DD.MM.YYYY', 'CET')))]), _c('div', {
              staticClass: "par-ereignis-beschreibung"
            }, [e.termin && e.termin.patyp > 0 && e.uptNr > 0 ? _c('div', {
              staticClass: "upt-nr",
              attrs: {
                "title": e.uptNr + ". UPT Behandlung"
              }
            }, [_vm._v(_vm._s(e.uptNr) + ".")]) : _vm._e(), e.hinweis ? _c('span', {
              staticClass: "terminhinweis"
            }, [_vm._v(_vm._s(e.hinweis) + ":")]) : _vm._e(), _c('span', [_vm._v(_vm._s(e.termin && e.termin.kommentar || e.beschreibung))]), e.termin && e.termin.patyp > 0 ? _c('div', {
              staticClass: "patyp",
              attrs: {
                "title": "Termintyp"
              }
            }, [_vm._v(_vm._s(e.termin.patypbezeichnung))]) : _vm._e()]), _c('div', {
              staticClass: "par-ereignis-leistungen"
            }, [_c('div', {
              staticClass: "erbracht"
            }, [_vm._v(_vm._s(_vm._f("parLeistungen")(e.leistungen)))]), e.vorschlaege ? _c('div', {
              staticClass: "vorschlaege"
            }, [_c('div', {
              staticClass: "vorschlag-label"
            }, [_vm._v("Vorschläge:")]), _c('div', {
              staticClass: "vorschlag"
            }, [_vm._v(_vm._s(_vm._f("vorschlaege")(e.vorschlaege)))])]) : _vm._e()]), _c('div', {
              staticClass: "par-ereignis-betrag"
            }, [e.betragabgerechnet > 0 || e.betragoffen > 0 ? _c('span', [_vm._v(_vm._s(_vm._f("euro")(e.betragabgerechnet + e.betragoffen)))]) : _vm._e()]), _c('div', {
              staticClass: "par-ereignis-fehler"
            }, [_c('div', {
              staticClass: "ereignis-hinweis overflow"
            }, [_c('span', [_vm._v(_vm._s(e.hinweis))])]), e.fehler && e.fehler.level === "FEHLER" ? _c('div', {
              staticClass: "ereignis-fehler overflow"
            }, [_vm._v(_vm._s(e.fehler.text))]) : _vm._e(), e.fehler && e.fehler.level === "WARNUNG" ? _c('div', {
              staticClass: "ereignis-warnung overflow"
            }, [_vm._v(_vm._s(e.fehler.text))]) : _vm._e()])]) : _vm._e(), e.typ === "MISSINGUPT" && e.fehler ? _c('div', {
              staticClass: "upt-missing"
            }, [_c('div', {
              staticClass: "par-ereignis-fehler"
            }, [e.fehler.level === "FEHLER" ? _c('div', {
              staticClass: "ereignis-fehler overflow"
            }, [_vm._v(_vm._s(e.fehler.text))]) : _vm._e(), e.fehler.level === "WARNUNG" ? _c('div', {
              staticClass: "ereignis-warnung overflow"
            }, [_vm._v(_vm._s(e.fehler.text))]) : _vm._e()])]) : _vm._e(), e.typ === "MISSINGBEV" && e.fehler ? _c('div', {
              staticClass: "bev-missing"
            }, [_c('div', {
              staticClass: "par-ereignis-fehler"
            }, [e.fehler.level === "FEHLER" ? _c('div', {
              staticClass: "ereignis-fehler overflow"
            }, [_vm._v(_vm._s(e.fehler.text))]) : _vm._e(), e.fehler.level === "WARNUNG" ? _c('div', {
              staticClass: "ereignis-warnung overflow"
            }, [_vm._v(_vm._s(e.fehler.text))]) : _vm._e()])]) : _vm._e(), e.typ === "MISSINGPA" && e.fehler ? _c('div', {
              staticClass: "pa-missing"
            }, [_c('div', {
              staticClass: "par-ereignis-fehler"
            }, [e.fehler.level === "FEHLER" ? _c('div', {
              staticClass: "ereignis-fehler overflow"
            }, [_vm._v(_vm._s(e.fehler.text))]) : _vm._e(), e.fehler.level === "WARNUNG" ? _c('div', {
              staticClass: "ereignis-warnung overflow"
            }, [_vm._v(_vm._s(e.fehler.text))]) : _vm._e()])]) : _vm._e(), e.typ === "UPTTERMINZEITMARKERSTART" || e.typ === "UPTTERMINZEITMARKERENDE" ? _c('div', {
              staticClass: "upt-interval-marker"
            }, [_c('div', {
              staticClass: "line-elem"
            }), _c('div', {
              staticClass: "line-date",
              attrs: {
                "title": e.typ
              }
            }, [_vm._v(_vm._s(_vm._f("tz")(e.tag, 'DD.MM.YYYY', 'CET')))]), _c('div', {
              staticClass: "line-text"
            }), _c('div', {
              staticClass: "line-elem"
            })]) : _vm._e(), e.typ === "UPTGMARKER" ? _c('div', {
              staticClass: "upt-g-marker"
            }, [_c('div', {
              staticClass: "line-elem"
            }), _c('div', {
              staticClass: "line-date",
              attrs: {
                "title": e.typ
              }
            }, [_vm._v(_vm._s(_vm._f("tz")(e.tag, 'DD.MM.YYYY', 'CET')))]), _c('div', {
              staticClass: "line-text"
            }, [_vm._v(": " + _vm._s(e.beschreibung))]), _c('div', {
              staticClass: "line-elem"
            })]) : _vm._e(), e.typ === "ENDMARKER" ? _c('div', {
              staticClass: "upt-end-marker"
            }, [_c('div', {
              staticClass: "line-elem"
            }), _c('div', {
              staticClass: "line-date",
              attrs: {
                "title": e.typ
              }
            }, [_vm._v(_vm._s(_vm._f("tz")(e.tag, 'DD.MM.YYYY', 'CET')))]), _c('div', {
              staticClass: "line-text"
            }, [_vm._v(": " + _vm._s(e.beschreibung))]), _c('div', {
              staticClass: "line-elem"
            })]) : _vm._e(), e.typ === "HKPVERLAENGERTAM" ? _c('div', {
              staticClass: "hkp-extension-start-marker"
            }, [_c('div', {
              staticClass: "line-text"
            }, [_vm._v(_vm._s(e.beschreibung))])]) : _vm._e(), e.typ === "HKPVERLAENGERTBIS" ? _c('div', {
              staticClass: "hkp-extension-end-marker"
            }, [_c('div', {
              staticClass: "line-text"
            }, [_vm._v(_vm._s(e.beschreibung))])]) : _vm._e(), e.typ === "MOREUPTS" ? _c('div', {
              staticClass: "upt-more-marker"
            }, [_c('div', {
              staticClass: "line-text"
            }, [_vm._v(_vm._s(e.beschreibung))])]) : _vm._e(), e.typ === "BEVINTERVALSTART" || e.typ === "BEVINTERVALENDE" ? _c('div', {
              staticClass: "pa-interval-marker"
            }, [_c('div', {
              staticClass: "line-elem"
            }), _c('div', {
              staticClass: "line-date",
              attrs: {
                "title": e.typ
              }
            }, [_vm._v(_vm._s(_vm._f("tz")(e.tag, 'DD.MM.YYYY', 'CET')) + ":")]), _c('div', {
              staticClass: "line-text"
            }, [_vm._v(_vm._s(e.beschreibung))]), _c('div', {
              staticClass: "line-elem"
            })]) : _vm._e(), e.typ === "ENDEPANBEHANDLUNGSZEITMARKER" ? _c('div', {
              staticClass: "pa-interval-marker"
            }, [_c('div', {
              staticClass: "line-elem"
            }), _c('div', {
              staticClass: "line-date",
              attrs: {
                "title": e.typ
              }
            }, [_vm._v(_vm._s(_vm._f("tz")(e.tag, 'DD.MM.YYYY', 'CET')))]), _c('div', {
              staticClass: "line-text"
            }, [_vm._v(": 3 Monate nach AIT")]), _c('div', {
              staticClass: "line-elem"
            })]) : _vm._e(), e.typ === "ENDECPTBEHANDLUNGSZEITMARKER" ? _c('div', {
              staticClass: "pa-interval-marker"
            }, [_c('div', {
              staticClass: "line-elem"
            }), _c('div', {
              staticClass: "line-date",
              attrs: {
                "title": e.typ
              }
            }, [_vm._v(_vm._s(_vm._f("tz")(e.tag, 'DD.MM.YYYY', 'CET')))]), _c('div', {
              staticClass: "line-text"
            }, [_vm._v(": 3 Monate nach CPT")]), _c('div', {
              staticClass: "line-elem"
            })]) : _vm._e(), e.typ === "CPTNURANTRAG" ? _c('div', {
              staticClass: "cpt-info"
            }, [_c('div', {
              staticClass: "line-elem"
            }), _c('div', {
              staticClass: "line-date"
            }), _c('div', {
              staticClass: "line-text",
              attrs: {
                "title": e.typ
              }
            }, [_vm._v(_vm._s(e.hinweis))]), _c('div', {
              staticClass: "line-elem"
            })]) : _vm._e()]);
          }), g.showTerminPlaceholder ? _c('div', {
            staticClass: "placeholder-for-empty-group"
          }, [_c('span', [_vm._v("Noch kein Termin geplant")])]) : _vm._e()], 2);
        }), _vm.selectedView === "UPT" ? _c('div', {
          staticClass: "more-less"
        }, [_vm.showMoreButton ? _c('div', {
          staticClass: "more-hint",
          on: {
            "click": function click($event) {
              return _vm.toggleFilter();
            }
          }
        }, [_c(VIcon, [_vm._v("fas fa-circle-plus")]), _c('span', [_vm._v("UPT-Intervalle einblenden")])], 1) : _vm._e(), _vm.showLessButton ? _c('div', {
          staticClass: "less-hint",
          on: {
            "click": function click($event) {
              return _vm.toggleFilter();
            }
          }
        }, [_c(VIcon, [_vm._v("fas fa-circle-minus")]), _c('span', [_vm._v("UPT-Intervalle ausblenden")])], 1) : _vm._e()]) : _vm._e()], 2), _c('div', {
          staticClass: "sum-upt-footer"
        }, [_vm.selectedPar.honorare.par.gesamt > 0 ? _c('div', {
          staticClass: "par-footer"
        }, [_c('div', {
          staticClass: "par-footer-gesamt-label"
        }, [_c('b', [_vm._v("PAR")]), _vm._v(" beantragt / erbracht")]), _c('div', {
          staticClass: "par-footer-gesamt-value"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.par.gesamt)))]), _c('span', [_vm._v("/")]), _c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.par.erbracht)))])]), _c('div', {
          staticClass: "par-footer-abgerechnet-label"
        }, [_vm._v("nicht- / abgerechnet")]), _c('div', {
          staticClass: "par-footer-abgerechnet-value"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.par.nichtAbgerechnet)))]), _c('span', [_vm._v("/")]), _c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.par.abgerechnet)))])])]) : _vm._e(), _vm.selectedPar.honorare.cpt.gesamt > 0 ? _c('div', {
          staticClass: "par-footer"
        }, [_c('div', {
          staticClass: "par-footer-gesamt-label"
        }, [_c('b', [_vm._v("CPT")]), _vm._v(" beantragt / erbracht")]), _c('div', {
          staticClass: "par-footer-gesamt-value"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.cpt.gesamt)))]), _c('span', [_vm._v("/")]), _c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.cpt.erbracht)))])]), _c('div', {
          staticClass: "par-footer-abgerechnet-label"
        }, [_vm._v("nicht- / abgerechnet")]), _c('div', {
          staticClass: "par-footer-abgerechnet-value"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.cpt.nichtAbgerechnet)))]), _c('span', [_vm._v("/")]), _c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.cpt.abgerechnet)))])])]) : _vm._e(), _vm.selectedPar.honorare.upt ? _c('div', {
          staticClass: "par-footer"
        }, [_c('div', {
          staticClass: "par-footer-gesamt-label"
        }, [_c('b', [_vm._v("UPT")]), _vm._v(" erbracht")]), _c('div', {
          staticClass: "par-footer-gesamt-value"
        }, [_c('span'), _c('span'), _c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.upt.erbracht)))])]), _c('div', {
          staticClass: "par-footer-abgerechnet-label"
        }, [_vm._v("nicht- / abgerechnet")]), _c('div', {
          staticClass: "par-footer-abgerechnet-value"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.upt.nichtAbgerechnet)))]), _c('span', [_vm._v("/")]), _c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.upt.abgerechnet)))])])]) : _vm._e(), _vm.selectedPar.honorare.gesamt.gesamt > 0 && _vm.selectedView === "UPT" ? _c('div', {
          staticClass: "par-footer par-footer-border"
        }, [_c('div', {
          staticClass: "par-footer-gesamt-label"
        }, [_c('b', [_vm._v("Gesamt")]), _vm._v(" beantragt / erbracht")]), _c('div', {
          staticClass: "par-footer-gesamt-value"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.gesamt.gesamt)))]), _c('span', [_vm._v("/")]), _c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.gesamt.erbracht)))])]), _c('div', {
          staticClass: "par-footer-abgerechnet-label"
        }, [_vm._v("nicht- / abgerechnet")]), _c('div', {
          staticClass: "par-footer-abgerechnet-value"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.gesamt.nichtAbgerechnet)))]), _c('span', [_vm._v("/")]), _c('span', [_vm._v(_vm._s(_vm._f("euro")(_vm.selectedPar.honorare.gesamt.abgerechnet)))])])]) : _vm._e()])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "par-status",
    "class": {
      "par-status-finished": _vm.selectedPar.finished,
      "par-multi": _vm.par.length > 1
    }
  }, [_vm.selectedPar.currentview === "PAR" ? _c('div', {
    staticClass: "par-status-grad"
  }, [_vm._v("Grad " + _vm._s(_vm.selectedPar.parstatus.grad))]) : _vm.selectedPar.currentview === "CPT" ? _c('div', {
    staticClass: "par-status-cpt"
  }, [_vm._v("CPT " + _vm._s(_vm.selectedPar.parstatus.grad))]) : _c('div', {
    staticClass: "par-status-upt"
  }, [_vm._v("UPT " + _vm._s(_vm.selectedPar.parstatus.grad))]), _vm.selectedPar.fehleranzahl && _vm.selectedPar.fehleranzahl.all > 0 ? _c('div', {
    staticClass: "par-anzahl-fehler"
  }, [_c('span', [_vm._v(_vm._s(_vm.selectedPar.fehleranzahl.all))])]) : _vm._e()])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };