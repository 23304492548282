import { defineComponent } from 'vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
export default defineComponent({
  components: {
    HelpTooltip: HelpTooltip
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    item: {
      get: function get() {
        var _a;

        return (_a = this.params.value) === null || _a === void 0 ? void 0 : _a.value;
      },
      set: function set(value) {
        var _a;

        this.params.context.clicked({
          key: (_a = this.params.value) === null || _a === void 0 ? void 0 : _a.key,
          value: value
        });
      }
    },
    buttons: function buttons() {
      return this.params.context.buttons;
    }
  }
});