import { ITerminResolveInfo } from '@rose/types';
import { fakerDe, CommonTerminInfoResolverService } from '@rose/common-ui';
import { collectorApi } from '@/services/data';
import { map } from 'lodash';
import { clientStateStore } from '@/state/clientStateStore';

export class TerminInfoResolverService extends CommonTerminInfoResolverService {
  public name = 'termine';
  public maxRetries = 5;

  loadInfo(ids: string[], options?: {}): Promise<ITerminResolveInfo[]> {
    if (clientStateStore.getters.isFakeData) {
      return Promise.resolve(
        map(ids, id => {
          const pn: ITerminResolveInfo = {
            extid: id || '',
            kommentar: fakerDe.lorem.sentence(10),
          };
          return pn;
        }),
      );
    }
    return collectorApi.bot.resolveTerminInfos(ids);
  }
}
