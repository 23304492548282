var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _vm.params.data ? _c('div', {
    staticClass: "leistungen-summary"
  }, [!_vm.params.data.isExtended ? _c('div', {
    staticClass: "summary-header-row d-flex align-center"
  }, [!_vm.showExtendedSummaryRow ? _c('font-awesome-icon', {
    staticClass: "mr-2 summary-icon",
    attrs: {
      "size": "sm",
      "icon": ["fas", "plus-circle"]
    }
  }) : _vm._e(), _vm.showExtendedSummaryRow ? _c('font-awesome-icon', {
    staticClass: "mr-2 summary-icon",
    attrs: {
      "size": "sm",
      "icon": ["fas", "minus-circle"]
    }
  }) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.params.data.title))]), _c('div', {
    staticClass: "spacer"
  }), _c('span', {
    staticClass: "pull-right"
  }, [_vm._v(_vm._s(_vm.params.data.count) + " " + _vm._s(_vm.params.data.count === 1 ? 'Eintrag' : 'Einträge'))])], 1) : _vm._e(), _vm.params.data.isExtended ? _c('div', {
    staticClass: "summary-detail-row",
    "class": {
      active: _vm.filterActive
    }
  }, [_c('div', {
    staticClass: "notting"
  }), _c('div', {
    staticClass: "pre"
  }, [_vm._v(_vm._s(_vm.params.data.title))]), _c('div', {
    staticClass: "count"
  }, [!_vm.filterOutOfCurrentDaterange ? _c('span', [_vm._v(_vm._s(_vm.params.data.count))]) : _vm._e()]), _c('div', {
    staticClass: "filter"
  }, [_c('font-awesome-icon', {
    attrs: {
      "size": "xs",
      "icon": ["fas", "filter"]
    }
  })], 1)]) : _vm._e()]) : _vm._e();
};

var staticRenderFns = [];
export { render, staticRenderFns };