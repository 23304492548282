/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ProtocolTable.vue?vue&type=template&id=69e1f9ee&scoped=true&lang=pug"
import script from "./ProtocolTable.vue?vue&type=script&lang=ts"
export * from "./ProtocolTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69e1f9ee",
  null
  
)

export default component.exports