import { ValueFormatterParams } from 'ag-grid-community';
import { chain, find, flatMap, map, size, sumBy, values } from 'lodash';
import {
  createEmptyWorkSheet,
  createCell,
  boldCellStyle,
  finalizeSimpleXLSXWithMetadata,
  zeroValueLightFGStyle,
} from '../../../base';
import {
  Units,
  IRechnungenDetailsMwStVerteilung,
  IRechnungExtended,
  ITeam,
  RechnungSearchRZFilter,
  RechnungSearchStatusFilter,
  RechnungSearchDateFilter,
  IRechnungExtendedWithVeraltetLeistungenFiltered,
  IRechnungBezahlt,
  ZahlungsArtType,
} from '../../../types';
import { createWorkBook, saveXLSX } from './exportExcel';
import { getValueFromGrid, getUnitFromGrid } from '../gridUtils';
import { IRoseAGGridColumn } from '../types/agGrid';
import { euroFilter } from '../filters/euro';
import { tzFilter } from '../filters/timezone';

function sumUpKey<T>(list: readonly T[], key: string): number {
  return sumBy(list, (r: any) => r[key]);
}

export function createSummaryRow(
  rechnungen: readonly IRechnungExtendedWithVeraltetLeistungenFiltered[],
  isExtended: boolean,
  title = 'SUMME',
  doFilter: any = null,
  spezialFilter: string[] | null = null,
  rowFilter?: (rd: IRechnungExtended & { veraltet: boolean }) => boolean,
) {
  const behandlerSumme: any = {};
  chain(rechnungen)
    .flatMap(r => r.honorardetails.behandlerverteilung)
    .compact()
    .forEach(bv => {
      if (!behandlerSumme[bv.behandler]) {
        behandlerSumme[bv.behandler] = { behandler: bv.behandler, betrag: bv.betrag, rest: bv.rest };
      } else {
        behandlerSumme[bv.behandler].betrag += bv.betrag;
        behandlerSumme[bv.behandler].rest += bv.rest;
      }
    })
    .value();
  const summaryHonorardetails = values(behandlerSumme);

  const res = {
    isSummary: true,
    isExtended,
    doFilter,
    spezialFilter,
    isExtendedSummaryRow: title !== 'SUMME',
    title,
    count: rechnungen.length,
    gesamt: sumUpKey(rechnungen, 'gesamt'),
    honorar: sumUpKey(rechnungen, 'honorar'),
    labor: sumUpKey(rechnungen, 'labor'),
    mahnbetrag: sumUpKey(rechnungen, 'mahnbetrag'),
    material: sumUpKey(rechnungen, 'material'),
    materialmwst: sumUpKey(rechnungen, 'materialmwst'),
    mwstnetto: sumUpKey(rechnungen, 'mwstnetto'),
    erstattung: sumUpKey(rechnungen, 'erstattung'),
    rest: sumUpKey(rechnungen, 'rest'),
    filteredLeistungen: [{ betrag: sumBy(rechnungen, r => sumBy(r.filteredLeistungen, l => l.betrag)) }],
    honorardetails: { behandlerverteilung: summaryHonorardetails },
    rowFilter,
  };
  return res;
}

export function downloadRechnungenExcel(
  columnDefs: IRoseAGGridColumn<IRechnungExtendedWithVeraltetLeistungenFiltered>[],
  rowData: IRechnungExtendedWithVeraltetLeistungenFiltered[],
  patientSearchInput: string,
  statusFilter: RechnungSearchStatusFilter,
  rzFilter: RechnungSearchRZFilter,
  team: ITeam | null,
  leistungserbringer: ITeam | null,
  dateFilterStatus: RechnungSearchDateFilter,
  from: string,
  to: string,
) {
  const wb = createWorkBook();
  const ws = createEmptyWorkSheet(wb, 'Rechnungen');
  let exportColumns = columnDefs;

  // find mwst sätze
  const elmwstsaetze = chain(rowData)
    .flatMap(r => r.labordetails?.eigenlabormwstverteilung)
    .compact()
    .map(m => m.mwstsatz)
    .uniq()
    .sortBy()
    .value();
  const elmmwstsaetze = chain(rowData)
    .flatMap(r => r.labordetails?.eigenlabormaterialmwstverteilung)
    .compact()
    .map(m => m.mwstsatz)
    .uniq()
    .sortBy()
    .value();
  const mmwstsaetze = chain(rowData)
    .flatMap(r => r.materialdetails?.mwstverteilung)
    .compact()
    .map(m => m.mwstsatz)
    .uniq()
    .sortBy()
    .value();
  const hmwstsaetze = chain(rowData)
    .flatMap(r => r.honorardetails?.mwstverteilung)
    .compact()
    .map(m => m.mwstsatz)
    .uniq()
    .sortBy()
    .value();

  // find zahlungsarten
  const zahlungsarten = chain(rowData)
    .flatMap(r => r.bezahltdetails)
    .compact()
    .map(m => m.zahlungsart)
    .uniq()
    .sortBy()
    .value();

  const nettoFormatter = (ms: number) => (params: { value: IRechnungenDetailsMwStVerteilung[] }) => {
    const x = find(params.value, v => v.mwstsatz === ms);
    return x ? x.betrag : 0;
  };
  const mwstFormatter = (ms: number) => (params: { value: IRechnungenDetailsMwStVerteilung[] }) => {
    const x = find(params.value, v => v.mwstsatz === ms);
    return x ? x.mwstbetrag : 0;
  };
  const zahlungsartFormatter = (zahlungsart: ZahlungsArtType) => (params: { value: IRechnungBezahlt[] }) =>
    chain(params.value)
      .filter(rb => rb.zahlungsart === zahlungsart)
      .sumBy(rb => rb.betrag)
      .value();
  const zahlungenFormatter = () => (params: { value: IRechnungBezahlt[] }) =>
    chain(params.value)
      .map(
        rb =>
          `${tzFilter.filters.tz(rb.tag)}/${tzFilter.filters.tz(rb.fibubuchdatum) || '-'}: ${euroFilter.filters.euro(
            rb.betrag,
          )}`,
      )
      .join(', ')
      .value();
  const zahlungenAnzahlFormatter = () => (params: { value: IRechnungBezahlt[] }) => size(params.value);

  exportColumns.push(
    {
      headerName: 'Honorar-Netto',
      valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
        params.data?.honorar + '' || '0',
      excelValueFormatter: (params: ValueFormatterParams<IRechnungExtended>) => params.data?.honorar || 0,
      useFormatterForExport: true,
      width: 85,
      exportUnit: Units.EURO,
    },
    ...flatMap(hmwstsaetze, ms => [
      {
        headerName: ms === 0 ? `Honorar-Netto ohne MwSt` : `Honorar-Netto ${ms}%`,
        field: 'honorardetails.mwstverteilung' as any /* TODO fix field typing */,
        valueFormatter: (params: { value: any }) => nettoFormatter(ms)(params).toString(),
        excelValueFormatter: nettoFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 130,
      },
      {
        headerName: `Honorar-MwSt ${ms}%`,
        field: 'honorardetails.mwstverteilung' as any /* TODO fix field typing */,
        valueFormatter: (params: { value: any }) => mwstFormatter(ms)(params).toString(),
        excelValueFormatter: mwstFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 130,
        noexport: ms === 0,
      },
    ]),
    {
      headerName: 'Fremdlabor',
      valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
        params.data?.fremdlabor + '' || '0',
      excelValueFormatter: (params: ValueFormatterParams<IRechnungExtended>) => params.data?.fremdlabor || 0,
      useFormatterForExport: true,
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Eigenlabor-Netto',
      valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
        params.data?.eigenlabor + '' || '0',
      excelValueFormatter: (params: ValueFormatterParams<IRechnungExtended>) => params.data?.eigenlabor || 0,
      useFormatterForExport: true,
      width: 100,
      exportUnit: Units.EURO,
    },
    ...flatMap(elmwstsaetze, ms => [
      {
        headerName: ms === 0 ? `Eigenlabor-Netto ohne MwSt` : `Eigenlabor-Netto ${ms}%`,
        field: 'labordetails.eigenlabormwstverteilung' as any /* TODO fix field typing */,
        valueFormatter: (params: { value: any }) => nettoFormatter(ms)(params).toString(),
        excelValueFormatter: nettoFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 110,
      },
      {
        headerName: `Eigenlabor-MwSt ${ms}%`,
        field: 'labordetails.eigenlabormwstverteilung' as any /* TODO fix field typing */,
        valueFormatter: (params: { value: any }) => mwstFormatter(ms)(params).toString(),
        excelValueFormatter: mwstFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        noexport: ms === 0,
        width: 100,
      },
    ]),
    {
      headerName: 'Eigenlabor-Material-Netto',
      valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
        params.data?.eigenlabormaterial + '' || '0',
      excelValueFormatter: (params: ValueFormatterParams<IRechnungExtended>) => params.data?.eigenlabormaterial || 0,
      useFormatterForExport: true,
      width: 100,
      exportUnit: Units.EURO,
    },
    ...flatMap(elmmwstsaetze, ms => [
      {
        headerName: ms === 0 ? `Eigenlabor-Material-Netto ohne MwSt` : `Eigenlabor-Material-Netto ${ms}%`,
        field: 'labordetails.eigenlabormaterialmwstverteilung' as any /* TODO fix field typing */,
        valueFormatter: (params: { value: any }) => nettoFormatter(ms)(params).toString(),
        excelValueFormatter: nettoFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 140,
      },
      {
        headerName: `Eigenlabor-Material-MwSt ${ms}%`,
        field: 'labordetails.eigenlabormaterialmwstverteilung' as any /* TODO fix field typing */,
        valueFormatter: (params: { value: any }) => mwstFormatter(ms)(params).toString(),
        excelValueFormatter: mwstFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        noexport: ms === 0,
        width: 130,
      },
    ]),
    {
      headerName: 'Material-Netto',
      valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
        params.data?.material + '' || '0',
      useFormatterForExport: true,
      width: 85,
      exportUnit: Units.EURO,
    },
    ...flatMap(mmwstsaetze, ms => [
      {
        headerName: ms === 0 ? `Material-Netto ohne MwSt` : `Material-Netto ${ms}%`,
        field: 'materialdetails.mwstverteilung' as any /* TODO fix field typing */,
        valueFormatter: (params: { value: any }) => nettoFormatter(ms)(params).toString(),
        excelValueFormatter: nettoFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 130,
      },
      {
        headerName: `Material-MwSt ${ms}%`,
        field: 'materialdetails.mwstverteilung' as any /* TODO fix field typing */,
        valueFormatter: (params: { value: any }) => mwstFormatter(ms)(params).toString(),
        excelValueFormatter: mwstFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        noexport: ms === 0,
        width: 130,
      },
    ]),
    ...flatMap(zahlungsarten, za => [
      {
        headerName: resolveZahlungsArt(za),
        field: 'bezahltdetails' as any /* TODO fix field typing */,
        valueFormatter: (params: { value: any }) => zahlungsartFormatter(za)(params).toString(),
        excelValueFormatter: zahlungsartFormatter(za),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 130,
      },
    ]),
    {
      headerName: '# Zahlungen',
      field: 'bezahltdetails',
      valueFormatter: (params: { value: any }) => zahlungenAnzahlFormatter()(params).toString(),
      excelValueFormatter: zahlungenAnzahlFormatter(),
      useFormatterForExport: true,
      width: 80,
      exportUnit: Units.TEXT,
    },
    {
      headerName: 'Zahlungen',
      field: 'bezahltdetails',
      valueFormatter: (params: { value: any }) => zahlungenFormatter()(params).toString(),
      excelValueFormatter: zahlungenFormatter(),
      useFormatterForExport: true,
      width: 300,
      exportUnit: Units.TEXT,
    },
  );

  exportColumns = chain(exportColumns)
    .compact()
    .reject(cd => !!cd?.noexport)
    .value();

  // write header
  let colCount = 0;
  let rowCount = 0;
  for (const cd of exportColumns) {
    createCell(ws, colCount++, rowCount, cd?.headerName, Units.NONE, boldCellStyle);
  }

  // write data
  const sortedRowData = chain(rowData)
    .sortBy(r => r.tag)
    .reverse()
    .value();
  console.log(`export data`, sortedRowData);
  for (const row of sortedRowData) {
    colCount = 0;
    rowCount++;
    for (const cd of exportColumns) {
      const unit = getUnitFromGrid(cd);
      const val: number = getValueFromGrid(row, cd);
      createCell(ws, colCount++, rowCount, val, unit, unit === Units.EURO ? zeroValueLightFGStyle(val) : undefined);
    }
  }

  const metaData: any = {
    Filter: patientSearchInput || '-',
    Status: statusFilter,
    'RZ-Filter': rzFilter,
    Stammbehandler: team?.name || '-',
    Leistungerbringer: leistungserbringer?.name || '-',
  };

  const selectedDateFormatted = `${from} - ${to}`;
  if (dateFilterStatus === RechnungSearchDateFilter.Erstellt) {
    metaData['Erstellt-Datum-Filter'] = selectedDateFormatted;
  }
  if (dateFilterStatus === RechnungSearchDateFilter.Faellig) {
    metaData['Fällig-Datum-Filter'] = selectedDateFormatted;
  }
  finalizeSimpleXLSXWithMetadata(
    wb,
    ws,
    metaData,
    colCount,
    rowCount,
    map(exportColumns, c => ({ wpx: Math.trunc(c?.exportWidth || c?.width || 80) })),
  );
  saveXLSX(wb, 'rechnungen.xlsx');
}

function resolveZahlungsArt(za: ZahlungsArtType) {
  switch (za) {
    case ZahlungsArtType.NONE:
      return '-';
    case ZahlungsArtType.BAR:
      return 'Bar';
    case ZahlungsArtType.SCHECK:
      return 'Scheck';
    case ZahlungsArtType.EC:
      return 'EC';
    case ZahlungsArtType.ERSTATTUNG:
      return 'Erstattung';
    case ZahlungsArtType.BANK1:
      return 'Bank 1';
    case ZahlungsArtType.BANK2:
      return 'Bank 2';
  }
}
