import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _vm.behandler ? _c('div', {
    staticClass: "behandler-chip d-flex flex-shrink-1 align-center justify-center"
  }, [_vm.isAddable ? _c(VChip, {
    key: _vm.behandler.extid,
    "class": {
      "auto-configured": _vm.isAutoconfigured,
      "in-table": _vm.inTable
    },
    attrs: {
      "small": "",
      "label": ""
    },
    on: {
      "click": _vm.addBehandler
    }
  }, [_vm.behandler.farbe ? _c(VAvatar, {
    staticClass: "mr-1 pd-3",
    attrs: {
      "color": _vm.behandler.farbe,
      "left": ""
    }
  }) : _vm._e(), _c('div', {
    staticClass: "span overflow-hidden",
    "class": {
      "strike-through": _vm.behandler.veraltet
    }
  }, [_vm._v(_vm._s(_vm.behandler.displayText) + " (" + _vm._s(_vm.behandler.extid) + ")")]), _vm.isDeletable ? _c(VIcon, {
    "class": {
      "auto-configured": _vm.isAutoconfigured
    },
    attrs: {
      "right": "",
      "small": ""
    },
    on: {
      "click": _vm.deleteBehandler
    }
  }, [_vm._v("fas fa-circle-x")]) : _vm._e()], 1) : _c(VChip, {
    key: _vm.behandler.extid,
    staticClass: "d-flex",
    "class": {
      "auto-configured": _vm.isAutoconfigured,
      "in-table": _vm.inTable
    },
    attrs: {
      "small": !_vm.xtraSmall,
      "label": "",
      "x-small": _vm.xtraSmall,
      "outlined": _vm.outlined
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.behandler.farbe ? _c(VAvatar, {
    staticClass: "mr-1",
    attrs: {
      "color": _vm.behandler.farbe
    }
  }) : _vm._e(), _c('div', {
    staticClass: "span overflow-hidden behandler-text text-truncate",
    "class": {
      "strike-through": _vm.behandler.veraltet
    }
  }, [_vm._v(_vm._s(_vm.behandler.displayText) + " (" + _vm._s(_vm.behandler.extid) + ")")]), _vm.isDeletable ? _c(VIcon, {
    "class": {
      "auto-configured": _vm.isAutoconfigured
    },
    attrs: {
      "right": "",
      "x-small": ""
    },
    on: {
      "click": _vm.deleteBehandler
    }
  }, [_vm._v("fas fa-circle-x")]) : _vm._e()], 1), _vm.behandler.farbe && _vm.manuallyConfiguredReasons ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c(VAvatar, {
    staticClass: "mr-1 pd-3",
    attrs: {
      "color": null,
      "left": ""
    }
  }), _c('div', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.manuallyConfiguredReasons))])], 1) : _vm._e()])])], 1) : _c('div', {
    staticClass: "no-behandler"
  }, [_vm._v("XXX")]);
};

var staticRenderFns = [];
export { render, staticRenderFns };