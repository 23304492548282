import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "team-mitglieder-cell"
  }, [_vm.isCustom ? _c('div', {
    staticClass: "eigene-team d-flex flex-wrap py-2"
  }, [_vm._l(_vm.mitglieder, function (t) {
    return _c('BehandlerChip', {
      key: t.extid,
      staticClass: "mr-2 my-1",
      attrs: {
        "behandler": t,
        "is-deletable": true,
        "in-table": true,
        "small": ""
      },
      on: {
        "deleteBehandler": function deleteBehandler($event) {
          return _vm.removeTeamMitglied(t);
        }
      }
    });
  }), _c(VMenu, {
    attrs: {
      "offset-y": "",
      "min-width": "300px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VBtn, _vm._g({
          staticClass: "ma-2 py-1",
          attrs: {
            "fab": "",
            "depressed": "",
            "x-small": ""
          }
        }, on), [_c(VIcon, [_vm._v("fa fa-plus")])], 1)];
      }
    }], null, false, 285611800)
  }, [_c(VList, {
    attrs: {
      "dense": ""
    }
  }, [_c(VVirtualScroll, {
    attrs: {
      "item-height": "35",
      "height": "500",
      "min-width": "250",
      "items": _vm.addableBehandler
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c(VListItem, {
          key: item.extid,
          attrs: {
            "dense": ""
          }
        }, [_c(VListItemContent, [_c(VListItemTitle, [_c('BehandlerChip', {
          staticClass: "mx-2",
          attrs: {
            "behandler": item,
            "is-addable": true
          },
          on: {
            "addBehandler": function addBehandler($event) {
              return _vm.addTeamMitglied(item);
            }
          }
        })], 1)], 1)], 1)];
      }
    }], null, false, 1489361217)
  })], 1)], 1)], 2) : _c('div', {
    staticClass: "auto-teams d-flex flex-wrap py-2"
  }, [_vm._l(_vm.mitglieder, function (t) {
    return _c('BehandlerChip', {
      key: t.extid,
      staticClass: "mr-2 my-1",
      attrs: {
        "behandler": t,
        "in-table": true,
        "small": ""
      }
    });
  }), _vm.showMore ? _c(VBtn, {
    staticClass: "mr-2 my-1",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showAll = !_vm.showAll;
      }
    }
  }, [_vm._v(_vm._s(_vm.showMore))]) : _vm._e()], 2)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };