import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "bot-protocol-table"
  }, [_c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, [_c(VCol, {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Worker")])]), _c(VCol, {
    staticClass: "py-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.importProtocolComplete.info.workerId) + "@" + _vm._s(_vm.importProtocolComplete.info.workerHost))])])], 1), _c(VRow, [_c(VCol, {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Import-Id")])]), _c(VCol, {
    staticClass: "py-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.importProtocolComplete.info.id))])])], 1), _c(VRow, [_c(VCol, {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Letzte Änderung")])]), _c(VCol, {
    staticClass: "py-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.protocolInfo.modelInfo.lastchange || _vm.importProtocolComplete.info.lastchange))])])], 1), _c(VRow, [_c(VCol, {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Dauer")])]), _c(VCol, {
    staticClass: "py-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm._f("dauer")(_vm.duration)))])])], 1), _vm.tableType === "collector" ? _c(VRow, {
    staticClass: "align-center"
  }, [_c(VCol, {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Daten empf.")])]), _c(VCol, {
    staticClass: "py-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.size))])])], 1) : _vm._e(), _c(VRow, [_c(VCol, {
    staticClass: "pt-0 pr-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Von - Bis")])]), _c(VCol, {
    staticClass: "pt-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.from.format('DD.MM.YYYY HH:mm:ss')) + " - " + _vm._s(_vm.to.format('DD.MM.YYYY HH:mm:ss')))])])], 1)], 1), _c('ag-grid-vue', {
    staticClass: "ag-theme-balham d-block fill-height",
    attrs: {
      "row-data": _vm.rowData,
      "locale-text": _vm.localeText,
      "grid-options": _vm.gridOptions,
      "column-defs": _vm.columnDefs
    },
    on: {
      "grid-ready": _vm.onGridReady,
      "cellClicked": _vm.onCellClicked
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };