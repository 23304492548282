import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex flex-wrap py-1 align-center"
  }, [_vm._l(_vm.terminBehandlerAssigned, function (tbAssigned) {
    return _c('BehandlerChip', {
      key: tbAssigned.extid,
      staticClass: "mr-2 py-1",
      attrs: {
        "behandler": tbAssigned,
        "is-autoconfigured": _vm.getIsAutoConfigured(tbAssigned),
        "is-deletable": true,
        "in-table": true
      },
      on: {
        "deleteBehandler": function deleteBehandler($event) {
          return _vm.deleteTerminBehandlerZuordnung(tbAssigned);
        }
      }
    });
  }), _c(VMenu, {
    attrs: {
      "offset-y": "",
      "min-width": "200px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VBtn, _vm._g({
          staticClass: "ma-2 py-1",
          attrs: {
            "fab": "",
            "depressed": "",
            "x-small": ""
          }
        }, on), [_c(VIcon, [_vm._v("fa fa-plus")])], 1)];
      }
    }])
  }, [_c(VList, {
    attrs: {
      "dense": ""
    }
  }, [_c(VListItemGroup, {
    attrs: {
      "dense": ""
    }
  }, [_c(VSubheader, [_vm._v("Aktiv")]), _vm._l(_vm.terminBehandlerNotAssignedAktiv, function (tbNotAssigned) {
    return _c(VListItem, {
      key: tbNotAssigned.extid,
      attrs: {
        "dense": ""
      }
    }, [_c(VListItemContent, [_c(VListItemTitle, [_c('BehandlerChip', {
      staticClass: "mx-1",
      attrs: {
        "behandler": tbNotAssigned,
        "is-addable": true
      },
      on: {
        "addBehandler": function addBehandler($event) {
          return _vm.addTerminbehandlerZuordnung(tbNotAssigned);
        }
      }
    })], 1)], 1)], 1);
  })], 2), _c(VListItemGroup, {
    attrs: {
      "dense": ""
    }
  }, [_c(VSubheader, [_vm._v("Veraltet")]), _vm._l(_vm.terminBehandlerNotAssignedVeraltet, function (tbNotAssigned) {
    return _c(VListItem, {
      key: tbNotAssigned.extid
    }, [_c(VListItemContent, [_c(VListItemTitle, [_c('BehandlerChip', {
      staticClass: "ma-1",
      attrs: {
        "behandler": tbNotAssigned,
        "is-addable": true
      },
      on: {
        "addBehandler": function addBehandler($event) {
          return _vm.addTerminbehandlerZuordnung(tbNotAssigned);
        }
      }
    })], 1)], 1)], 1);
  })], 2)], 1)], 1)], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };