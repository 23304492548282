var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "cell"
  }, [_vm.hasComments ? _c('font-awesome-icon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Hat Kommentare",
      expression: "`Hat Kommentare`"
    }],
    staticClass: "mx-1 kommentar-icon",
    attrs: {
      "size": "sm",
      "icon": ["fas", "comment"]
    }
  }) : _vm._e(), _vm.hasHkpRef ? _c('font-awesome-icon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Hat HKP Referenzen",
      expression: "`Hat HKP Referenzen`"
    }],
    staticClass: "mx-1 kommentar-icon",
    attrs: {
      "size": "sm",
      "icon": ["fas", "file"]
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };