import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.string.link.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "small": "",
      "href": _vm.link
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-download")])], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };