var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "open-in-charly"
  }, [_vm.showRoseHelperRocket ? _c('font-awesome-icon', {
    staticClass: "ml-2 open-in-charly-icon open-in-charly-chrome",
    attrs: {
      "data-patid": _vm.patid,
      "data-dialog": _vm.dialog,
      "size": "sm",
      "title": "Patient mit rose-helper in charly öffnen",
      "icon": ["fas", "rocket"]
    },
    on: {
      "click": function click($event) {
        return _vm.onIconClicked();
      }
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };