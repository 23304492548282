import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex pa-3"
  }, [_c(VSwitch, {
    attrs: {
      "color": "success",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.aktiv,
      callback: function callback($$v) {
        _vm.aktiv = $$v;
      },
      expression: "aktiv"
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };