import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "avatar-cell"
  }, [_vm.isTeam ? _c(VBadge, {
    attrs: {
      "avatar": "",
      "bordered": "",
      "color": "#009ee0",
      "overlap": ""
    },
    scopedSlots: _vm._u([{
      key: "badge",
      fn: function fn() {
        return [_c(VAvatar, [_c('RoseLogo')], 1)];
      },
      proxy: true
    }], null, false, 748658157)
  }, [_c(VAvatar, {
    attrs: {
      "color": "grey lighten-1",
      "size": "40"
    }
  }, [_c('span', {
    staticClass: "white--text text-h6"
  }, [_vm._v(_vm._s(_vm.initials))])])], 1) : _vm._e(), !_vm.isTeam && _vm.isAdmin ? _c(VBadge, {
    attrs: {
      "bordered": "",
      "color": _vm.badgeColor,
      "icon": _vm.icon,
      "overlap": ""
    }
  }, [_c(VAvatar, {
    attrs: {
      "color": "grey lighten-1",
      "size": "40"
    }
  }, [_c('span', {
    staticClass: "white--text text-h6"
  }, [_vm._v(_vm._s(_vm.initials))])])], 1) : _vm._e(), !_vm.isTeam && !_vm.isAdmin ? _c(VAvatar, {
    attrs: {
      "color": "grey lighten-1",
      "size": "40"
    }
  }, [_c('span', {
    staticClass: "white--text text-h6"
  }, [_vm._v(_vm._s(_vm.initials))])]) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };