import { VApp } from 'vuetify/lib/components/VApp';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c(VApp, {
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    key: _vm.cid,
    staticClass: "maincontent"
  }, [_vm.initialized && _vm.clientDataInitialized ? _c('router-view') : _vm._e()], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };