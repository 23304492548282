import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex"
  }, _vm._l(_vm.icons, function (icon) {
    return _c('div', {
      key: icon.title,
      staticClass: "d-flex align-center"
    }, [icon.value ? _c('div', {
      staticClass: "d-flex"
    }, [_c(VIcon, {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: icon.debugInfo,
        expression: "icon.debugInfo",
        arg: "copy"
      }, {
        name: "tooltip",
        rawName: "v-tooltip",
        value: icon.teamId || icon.title,
        expression: "icon.teamId || icon.title"
      }],
      staticClass: "teamicon",
      attrs: {
        "left": "",
        "size": "lg"
      }
    }, [_vm._v("fas fa-" + _vm._s(icon.icon))])], 1) : _vm._e(), !_vm.isEditable && icon.showTitle ? _c('div', {
      staticClass: "d-flex"
    }, [_vm._v(_vm._s(icon.title))]) : _vm._e(), _vm.isEditable && icon.showTitle ? _c(VTextField, {
      staticStyle: {
        "display": "inline-block"
      },
      attrs: {
        "type": "text",
        "clearable": false,
        "dense": "",
        "flat": "",
        "outlined": "",
        "hide-details": "",
        "small": ""
      },
      on: {
        "input": function input($event) {
          return _vm.changedIconTitle(icon.title);
        }
      },
      model: {
        value: icon.title,
        callback: function callback($$v) {
          _vm.$set(icon, "title", $$v);
        },
        expression: "icon.title"
      }
    }) : _vm._e()], 1);
  }), 0);
};

var staticRenderFns = [];
export { render, staticRenderFns };