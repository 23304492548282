var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex flex-row flex-wrap my-4 align-center"
  }, [_vm.stichwort ? _c('StichwortChip', {
    staticClass: "my-2",
    attrs: {
      "stichwort": _vm.stichwort
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };