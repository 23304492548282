import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _vm.konfiguration ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "d-flex pa-2"
  }, [_vm.konfiguration.leistung ? _c(VAutocomplete, {
    attrs: {
      "items": _vm.availableLeistungen,
      "filter": _vm.filterWithGroups,
      "chips": "",
      "clearable": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "flat": "",
      "label": "Leistung",
      "item-text": "nummer",
      "item-value": "nummer",
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex flex-row"
        }, [_c('div', {
          staticClass: "pr-3 nummer"
        }, [_vm._v(_vm._s(data.item.nummer))]), _c('span', {
          staticClass: "text"
        }, [_vm._v(_vm._s(data.item.text))])])];
      }
    }], null, false, 1780810175),
    model: {
      value: _vm.fehlendeLeistung,
      callback: function callback($$v) {
        _vm.fehlendeLeistung = $$v;
      },
      expression: "fehlendeLeistung"
    }
  }) : _vm._e()], 1), _vm.konfiguration.woAnbieten ? _c('div', {
    staticClass: "d-flex pa-2 pt-0"
  }, [_c(VSelect, {
    attrs: {
      "items": _vm.availableWoAnbieten,
      "label": "Anbieten bei",
      "item-text": "name",
      "item-value": "value",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.woAnbieten,
      callback: function callback($$v) {
        _vm.woAnbieten = $$v;
      },
      expression: "woAnbieten"
    }
  })], 1) : _vm._e(), _vm.konfiguration.triggerLeistungen ? _c('div', {
    staticClass: "d-flex pa-2 pt-0"
  }, [_c(VSelect, {
    attrs: {
      "items": _vm.availableTriggerleistungen,
      "label": "Vorschlagen zu",
      "item-text": "name",
      "item-value": "value",
      "multiple": "",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.triggerLeistungen,
      callback: function callback($$v) {
        _vm.triggerLeistungen = $$v;
      },
      expression: "triggerLeistungen"
    }
  })], 1) : _vm._e(), _vm.konfiguration.minAlter ? _c('div', {
    staticClass: "d-flex pa-2 pt-0"
  }, [_c(VTextField, {
    staticClass: "mr-2",
    attrs: {
      "hint": "Mindestalter Patient",
      "type": "number",
      "label": "Mindestalter Patient",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.minAlter,
      callback: function callback($$v) {
        _vm.minAlter = $$v;
      },
      expression: "minAlter"
    }
  }), _c(VBtn, {
    attrs: {
      "color": "red",
      "fab": "",
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.deleteMinAlter
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mx-1",
    attrs: {
      "size": "lg",
      "icon": ["fas", "trash"]
    }
  })], 1)], 1) : _vm._e()]) : _vm._e();
};

var staticRenderFns = [];
export { render, staticRenderFns };